import { Outlet, Route, Routes } from 'react-router';
import CreateTemplate from './views/CreateTemplate';
import EditTemplate from './views/EditTemplate';
import TemplatesView from './views/TemplatesView';

const TemplatesWrapper = () => {
    return <Outlet></Outlet>;
};

const TemplatesRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<TemplatesWrapper />}>
                <Route index element={<TemplatesView />} />
                <Route path='create' element={<CreateTemplate />} />
                <Route path=':id/edit' element={<EditTemplate />} />
            </Route>
        </Routes>
    );
};

export default TemplatesRoutes;
