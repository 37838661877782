import type { Transaction, TransactionProvider, TransactionSubType, TranslationKey } from '@nexdynamic/squeegee-common';
import * as common from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { AuditManager } from '../AuditManager';
import { AccountsLedgerService } from '../ChartOfAccounts/AccountsLedgerService';
import { VATServiceV1 } from '../ChartOfAccounts/VATService';
import { MatchPaymentService } from '../ConnectedServices/Payments/MatchPaymentService';
import { Data } from '../Data/Data';
import type { DataChangeNotificationType } from '../Data/DataChangeNotificationType';
import { InvoiceService } from '../Invoices/InvoiceService';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { PaymentsApi } from '../Server/PaymentsApi';
import { Utilities } from '../Utilities';
import { TransactionSavedMessage } from './TransactionMessages';

export class TransactionService {
    public static getTransTypeDescription(transaction: common.Transaction): TranslationKey {
        switch (transaction.transactionType) {
            case common.TransactionType.Invoice:
                if (transaction.transactionSubType === 'invoice.tip') return 'transaction.describe-tip';
                if (transaction.amount < 0) {
                    if (transaction.voidedId) return 'transaction.describe-invoice-cancellation';
                    return 'transaction.describe-customer-credit';
                }
                if (transaction.voidedId) return 'transaction.describe-cancelled-invoice';
                return 'transaction.describe-invoice';
            case common.TransactionType.Payment:
                if (transaction.transactionSubType?.includes('tip')) return 'transaction.describe-tip';
                if (transaction.amount > 0) return 'transaction.describe-refund';
                if (transaction.voidedId) return 'transaction.describe-cancelled-payment';
                if (transaction.paymentDetails?.refundId) return 'transaction.describe-refunded-payment';
                return 'transaction.describe-payment';
            case common.TransactionType.Adjustment:
                if (transaction.voidedId) return 'transaction.describe-cancelled-adjustment';
                return 'transaction.describe-adjustment';

            case common.TransactionType.AutomaticPayment:
                return ApplicationState.dd;
            case common.TransactionType.Transfer:
                return 'transaction.describe-transfer';
            default:
                return 'transaction.describe-unknown';
        }
    }
    public static getUnmatchedTransactions(
        provider: common.TransactionProvider,
        startDate: moment.Moment = moment('1980-01-01'),
        endDate: moment.Moment = moment()
    ) {
        if (!provider) throw 'No provider';

        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.clone().add(1, 'day').format('YYYY-MM-DD');

        const transactionSubTypesForProvider = this.getTransactionSubTypeByProvider(provider);

        const payments = Data.all<common.Transaction>(
            'transactions',
            t => {
                return (
                    t.amount != 0 &&
                    !t.externalIds?.[provider] &&
                    (!transactionSubTypesForProvider ||
                        (!!t.transactionSubType && transactionSubTypesForProvider.indexOf(t.transactionSubType) > -1))
                );
            },
            { transactionType: common.TransactionType.Payment }
        ).slice();

        const expenses = Data.all<common.Transaction>(
            'transactions',
            t => {
                return (
                    t.amount != 0 &&
                    !t.externalIds?.[provider] &&
                    t.date >= start &&
                    t.date < end &&
                    (!transactionSubTypesForProvider ||
                        (!!t.transactionSubType && transactionSubTypesForProvider.indexOf(t.transactionSubType) > -1))
                );
            },
            { transactionType: common.TransactionType.Expense }
        ).slice();
        return payments.concat(expenses).sort((a, b) => (a.date < b.date ? 1 : -1));
    }

    private static getTransactionSubTypeByProvider(provider: common.TransactionProvider): Array<TransactionSubType> | undefined {
        switch (provider) {
            case 'truelayer':
            case 'starling':
                return ['payment.bank-transfer', 'payment.refund', 'expense.standard', 'expense.squeegee', 'expense.other'];
            case 'stripe':
            case 'square':
                return ['payment.card', 'payment.stripe', 'payment.refund', 'auto.stripe', 'payment.other', 'payment.tip.card'];
            case 'gocardless':
                return ['auto.go-cardless', 'payment.other', 'payment.tip.other', 'payment.tip.bank-transfer', 'payment.bank-transfer'];
        }
    }

    public static getUnmatchedInvoices(startDate: moment.Moment = moment('1980-01-01'), endDate: moment.Moment = moment()) {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.clone().add(1, 'day').format('YYYY-MM-DD');

        return Data.all<common.InvoiceTransaction>(
            'transactions',
            t => {
                return !t.voidedId && t.amount != 0 && t.date >= start && t.date < end && !t.invoice?.paymentReference;
            },
            { transactionType: common.TransactionType.Invoice }
        );
    }

    public static getPayments(startDate: moment.Moment = moment('1980-01-01'), endDate: moment.Moment = moment()) {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.clone().add(1, 'day').format('YYYY-MM-DD');

        const payments = Data.all<common.Transaction>(
            'transactions',
            t => {
                return t.date >= start && t.date < end;
            },
            { transactionType: common.TransactionType.Payment }
        );

        const autoPayments = Data.all<common.Transaction>(
            'transactions',
            t => {
                return t.date >= start && t.date < end;
            },
            { transactionType: common.TransactionType.AutomaticPayment }
        );

        return payments.concat(autoPayments);
    }

    //WTF? 1980-01-01 is the beginning of time. Just so you know.
    public static getInvoices(startDate: moment.Moment = moment('1980-01-01'), endDate: moment.Moment = moment()) {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.clone().add(1, 'day').format('YYYY-MM-DD');

        return Data.all<common.Transaction>(
            'transactions',
            t => {
                const date = (t.invoice && t.invoice.date) || t.date || t.createdDate;

                return date >= start && date < end;
            },
            { transactionType: common.TransactionType.Invoice }
        ).slice();
    }

    public static getTransaction(id: string) {
        return Data.get<common.Transaction>(id);
    }

    public static async retryAutomaticPayment(transaction: common.Transaction) {
        if (!transaction || !transaction.paymentDetails) throw 'Unable to retry payment: No transaction payment details provided.';
        if (!transaction.paymentDetails.paymentProvider) throw 'Unable to retry payment: No payment provider provided.';
        if (transaction.status === 'awaitingApiHandling') throw 'Unable to retry payment: It is being processed, try again in a minute.';
        switch (transaction.paymentDetails.paymentProvider) {
            case 'gocardless': {
                if (transaction.status === 'complete')
                    throw `Unable to retry payment, the status is already COMPLETE. PAYMENT ID:${transaction.paymentDetails.paymentId}  REF:'
                        + ${transaction.paymentDetails.reference} + ' MANDATE ID: ${transaction.paymentDetails.mandateId}`;

                break;
            }
            case 'stripe':
            default:
                throw 'Not implemented "' + transaction.paymentDetails.paymentProvider + '" automated payments yet.';
        }

        if (!transaction.paymentDetails.paymentId) return;

        const response = await PaymentsApi.retryCustomerPayment(transaction);

        if (!response.success) throw (response && response.error) || undefined;

        // WTF: update the invoice status so that if it fails it returns to unpaid and if not it stays paid
        const voidTransaction = transaction.voidedId && Data.get(transaction.voidedId);
        if (voidTransaction) Data.delete(voidTransaction);
        if (transaction.customerId) await InvoiceService.allocateBalanceToInvoices(transaction.customerId);
    }

    public static async cancelAutomaticPayment(transaction: common.Transaction) {
        if (!transaction || !transaction.paymentDetails) throw 'Unable to cancel payment as no transaction payment details provided.';
        if (transaction.status === 'awaitingApiHandling') throw 'Unable to cancel payment as it is being processed, try again in a minute.';
        if (transaction.paymentDetails?.refundId) throw 'Payment was recorded as refunded to the customer.';

        switch (transaction.paymentDetails.paymentProvider) {
            case 'gocardless': {
                if (transaction.status === 'complete')
                    throw `Unable to cancel payment as it is complete so can no longer be cancelled. The GoCardless payment ID is ${transaction.paymentDetails.paymentId} and the mandate ID is ${transaction.paymentDetails.mandateId}`;

                break;
            }
            case 'stripe':
            default:
                throw 'Not implemented "' + transaction.paymentDetails.paymentProvider + '" automated payments yet.';
        }

        if (!transaction.paymentDetails.paymentId) return;

        const response = await PaymentsApi.cancelCustomerPayment(transaction);

        if (!response.success) {
            throw (response && response.error) || 'Unable to cancel payment as there is no response from payment service.';
        }
    }

    public static async cancelTransaction(
        transaction: common.Transaction,
        suppressEvents = false,
        objectsToPersist: { [id: string]: common.StoredObject } = {},
        deleteOnCancel?: 'onSuccess' | 'always'
    ) {
        const notify: DataChangeNotificationType = suppressEvents ? 'lazy' : 'immediate';

        try {
            if (transaction.transactionType === common.TransactionType.Transfer) {
                const transfer = transaction as common.TransferTransaction;
                const targetTransaction = Data.get<common.TransferTransaction>(transfer.targetTransactionid);
                await Data.delete(transfer);
                if (targetTransaction) await Data.delete(targetTransaction);
                return;
            }

            if (
                transaction.transactionType === common.TransactionType.AutomaticPayment &&
                transaction.status !== 'failed' &&
                !transaction.transactionSubType?.startsWith('payment.custom-')
            ) {
                try {
                    if (transaction.paymentDetails?.paymentProvider) await TransactionService.cancelAutomaticPayment(transaction);
                } catch (error) {
                    const message =
                        typeof error === 'string'
                            ? error
                            : 'Unable to cancel the associated automatic payment with the provider due to an unknown error ';
                    new NotifyUserMessage(message as common.TranslationKey);
                }
            }

            if (
                (transaction.transactionType === common.TransactionType.Payment ||
                    transaction.transactionType === common.TransactionType.AutomaticPayment) &&
                transaction.paymentDetails &&
                transaction.paymentDetails.invoiceIds
            ) {
                for (const invoiceId of transaction.paymentDetails.invoiceIds) {
                    const invoiceTransaction = Data.get<common.Transaction>(invoiceId);
                    if (invoiceTransaction && invoiceTransaction.invoice) {
                        invoiceTransaction.invoice.paid = false;
                        invoiceTransaction.invoice.paymentReference = '';
                        objectsToPersist[invoiceTransaction._id] = invoiceTransaction;
                    }
                }
                transaction.paymentDetails.invoiceIds = [];
            }

            let voidTransaction: Transaction | undefined;
            if (!deleteOnCancel) {
                if (
                    transaction.transactionType !== common.TransactionType.AutomaticPayment ||
                    !transaction.paymentDetails?.paymentProvider ||
                    transaction.paymentDetails?.paymentProvider?.startsWith('payment.custom-')
                ) {
                    if (transaction.voidedId) throw 'Transaction is already marked as cancelled.';

                    if (transaction.externalIds?.truelayer) delete transaction.externalIds.truelayer;

                    const result = common.VoidOrReversalTransaction.createVoid({
                        transaction,
                        allocatedInvoiceTransactions: [],
                        paidOccurrences: [],
                    });

                    voidTransaction = result.void;

                    transaction.voidedId = voidTransaction._id;
                    AuditManager.recordAuditEvent('transaction-void', [transaction, voidTransaction]);
                }
            } else {
                if (transaction.voidedId) {
                    const voidTransaction = Data.get<common.Transaction>(transaction.voidedId);
                    if (voidTransaction) {
                        voidTransaction.voidedId = undefined;
                        await Data.put(voidTransaction, true, 'lazy');
                    }
                }
                if (transaction.paymentDetails?.refundId) {
                    if (transaction.amount > 0) {
                        const refundedPayment = Data.get<common.Transaction>(transaction.paymentDetails.refundId);
                        if (refundedPayment?.paymentDetails?.refundId) {
                            refundedPayment.paymentDetails.refundId = undefined;
                            await Data.put(refundedPayment);
                        }
                    } else {
                        const refund = Data.get<common.Transaction>(transaction.paymentDetails.refundId);
                        if (refund) await Data.delete(refund);
                    }
                }
                transaction._deleted = true;
            }
            await Data.put(transaction, true, notify);

            await Data.put(
                Object.keys(objectsToPersist).map(x => objectsToPersist[x]),
                true,
                notify
            );

            if (voidTransaction?.voidedId) {
                const voidedTransactionExists = !!Data.get<Transaction>(voidTransaction.voidedId);
                if (voidedTransactionExists) await Data.put(voidTransaction);
            }

            if (!suppressEvents) new TransactionSavedMessage(transaction);
        } catch (error) {
            Logger.error('Error cancelling the transaction.', { error, transaction, suppressEvents, objectsToPersist, deleteOnCancel });
            if (deleteOnCancel === 'always') {
                transaction._deleted = true;
                await Data.put(transaction, true, notify);
            }
            throw error;
        } finally {
            const customerId = transaction.customerId;
            if (customerId) await InvoiceService.allocateBalanceToInvoices(customerId);
        }
    }

    public static getAccountTransactions(provider?: TransactionProvider, paymentAccountId?: string | 'all') {
        if (paymentAccountId === ApplicationState.creditorAccountId) {
            return AccountsLedgerService.getCreditorLedger();
        }
        if (paymentAccountId === ApplicationState.debtorAccountId) {
            return Data.all<Transaction>('transactions', t => t.customerId !== undefined).slice();
        }
        if (paymentAccountId === ApplicationState.taxAccountId) {
            return VATServiceV1.getVATLedger();
        }

        if (paymentAccountId === 'all') {
            paymentAccountId = undefined;
        }

        return Data.all<common.Transaction>(
            'transactions',
            (t: common.PaymentTransaction) =>
                (!provider || (!!t.externalIds && !!t.externalIds[provider])) &&
                (!paymentAccountId || t.paymentDetails?.paymentAccountId === paymentAccountId || t.accountId === paymentAccountId),
            { transactionType: TransactionType.Payment }
        )
            .concat(
                Data.all<common.Transaction>(
                    'transactions',
                    (t: common.PaymentTransaction) =>
                        (!provider || (!!t.externalIds && !!t.externalIds[provider])) &&
                        (!paymentAccountId || t.paymentDetails?.paymentAccountId === paymentAccountId || t.accountId === paymentAccountId),
                    { transactionType: TransactionType.AutomaticPayment }
                )
            )
            .concat(
                Data.all<common.Transaction>(
                    'transactions',
                    (t: common.Expense) =>
                        (!provider || (!!t.externalIds && !!t.externalIds[provider])) &&
                        (!paymentAccountId || t.paymentAccountId === paymentAccountId || t.accountId === paymentAccountId),
                    { transactionType: TransactionType.Expense }
                )
            )
            .concat(
                Data.all<common.Transaction>(
                    'transactions',
                    (t: common.TransferTransaction) =>
                        (!provider || (!!t.externalIds && !!t.externalIds[provider])) &&
                        (!paymentAccountId || t.paymentAccountId === paymentAccountId || t.accountId === paymentAccountId),
                    { transactionType: TransactionType.Transfer }
                )
            );
    }

    public static getAllPayments() {
        return Data.all<common.Transaction>('transactions', undefined, { transactionType: TransactionType.Payment }).concat(
            Data.all<common.Transaction>('transactions', undefined, { transactionType: TransactionType.AutomaticPayment })
        );
    }

    public static getAllPaymentsByDay() {
        return this.getTransactionsByDay(this.getAllPayments(), false);
    }

    public static getAccountTransactionsUnmatched(provider: TransactionProvider, paymentAccountId: string, includeMatched: boolean) {
        const transactions: Array<common.Transaction> = [];

        transactions.push(
            ...Data.all<common.PaymentTransaction>(
                'transactions',
                (t: common.PaymentTransaction) =>
                    !t.voidedId &&
                    (includeMatched || !t.externalIds || !t.externalIds[provider]) &&
                    (!paymentAccountId || !t.paymentDetails?.paymentAccountId || t.paymentDetails?.paymentAccountId === paymentAccountId),
                { transactionType: TransactionType.Payment }
            )
        );

        transactions.push(
            ...Data.all<common.Expense>(
                'transactions',
                (t: common.Expense) =>
                    !t.voidedId &&
                    (includeMatched || !t.externalIds || !t.externalIds[provider]) &&
                    (!t.paymentAccountId || !paymentAccountId || t.paymentAccountId === paymentAccountId),
                { transactionType: TransactionType.Expense }
            )
        );

        transactions.push(
            ...Data.all<common.TransferTransaction>(
                'transactions',
                (t: common.TransferTransaction) =>
                    !t.voidedId &&
                    (includeMatched || !t.externalIds || !t.externalIds[provider]) &&
                    (!paymentAccountId || t.paymentAccountId === paymentAccountId),
                { transactionType: TransactionType.Transfer }
            )
        );
        return transactions;
    }
    public static getAccountTransactionsByDay(paymentAccountId?: string, provider?: TransactionProvider) {
        return this.getTransactionsByDay(this.getAccountTransactions(provider, paymentAccountId));
    }

    public static getDuplicatedAccountTransactionsByDesc(paymentAccountId: string) {
        const transactions = this.getAccountTransactions(undefined, paymentAccountId);
        return MatchPaymentService.findDuplicates(transactions);
    }

    public static getTransIncVoidsByDay(transactionType: common.TransactionType) {
        const transactions = Data.all<common.Transaction>('transactions', { transactionType });
        return this.getTransactionsByDay(transactions);
    }

    public static getCustomerInvoicesExcludingVoids(customerId: string) {
        // Don't include credit notes, refunds, or write offs or anything that has a negative balance ie customer in credit
        return TransactionService.getCustomerTransactionsIncludingVoids(customerId).filter(
            transaction => transaction.amount > 0 && !transaction.voidedId && transaction.transactionType === common.TransactionType.Invoice
        );
    }

    public static getCustomerTransactionsIncludingVoids(
        customerId: string,
        transactionTypes?: common.TransactionType | Array<common.TransactionType>
    ) {
        transactionTypes = transactionTypes === undefined ? [] : Array.isArray(transactionTypes) ? transactionTypes : [transactionTypes];
        if (!transactionTypes.length) return Data.all<common.Transaction>('transactions', { customerId }).slice();

        let trans = [] as Array<common.Transaction>;
        for (const transactionType of transactionTypes) {
            trans = trans.concat(Data.all<common.Transaction>('transactions', { customerId, transactionType }));
        }

        trans.sort(common.sortByCreatedDateDesc);

        return trans;
    }

    public static getPaymentAccountTransactionsIncludingVoids(paymentAccountId: string) {
        const trans = Data.all<common.Transaction>('transactions', t => t.paymentDetails?.paymentAccountId === paymentAccountId, {
            transactionType: TransactionType.Payment,
        }).slice();

        trans.sort(common.sortByCreatedDateDesc);

        return trans;
    }

    public static getCustomerTransIncVoidsByDay(
        customerId: string,
        transactionTypes?: common.TransactionType | Array<common.TransactionType>
    ) {
        const trans = TransactionService.getCustomerTransactionsIncludingVoids(customerId, transactionTypes);
        return TransactionService.getTransactionsByDay(trans);
    }

    public static getPaymentAccountTransIncVoidsByDay(paymentAccountId: string) {
        const trans = TransactionService.getPaymentAccountTransactionsIncludingVoids(paymentAccountId);
        return TransactionService.getTransactionsByDay(trans);
    }

    public static getCustomerBalance(
        customerId: string,
        dueDate?: string,
        includeTheseTransIfTheyDontExist?: Array<Transaction>
    ): common.ICustomerBalance {
        const transactions = this.getCustomerTransactionsIncludingVoids(customerId);
        if (includeTheseTransIfTheyDontExist) {
            for (const t of includeTheseTransIfTheyDontExist) {
                if (!transactions.find(x => x._id === t._id)) transactions.push(t);
            }
        }
        const balance = common.TransactionUtilities.getBalance(transactions, dueDate);
        return balance || { amount: 0, debtAgeDays: 0, overdue: 0 };
    }

    public static getTransactionsByDay(transactions: Readonly<Array<common.Transaction>>, includeVoids = true) {
        const transByDay: {
            [text: string]: { [transactionId: string]: common.Transaction };
        } = {};

        const hideCancelled = ApplicationState.getSetting('global.hide-cancelled-transactions', false);
        for (const transaction of transactions) {
            if (!includeVoids && hideCancelled && transaction.voidedId && Data.get(transaction.voidedId)) continue;
            const day = transaction.date.substring(0, 10);
            if (!transByDay[day]) transByDay[day] = {};
            transByDay[day][transaction._id] = transaction;
        }

        return transByDay;
    }
    public static getTransactionsByDesc(transactions: Readonly<Array<common.Transaction>>, includeVoids = true) {
        const transByDay: {
            [text: string]: { [transactionId: string]: common.Transaction };
        } = {};

        const hideCancelled = ApplicationState.getSetting('global.hide-cancelled-transactions', false);
        for (const transaction of transactions) {
            if (!includeVoids && hideCancelled && transaction.voidedId && Data.get(transaction.voidedId)) continue;
            const key = transaction.description + transaction.amount.toString();
            if (!transByDay[key]) transByDay[key] = {};
            transByDay[key][transaction._id] = transaction;
        }

        return transByDay;
    }

    public static createAutomaticPayment({
        paymentProvider,
        type,
        customerId,
        amount,
        additionalReference,
        initiatorReference,
        invoiceIds,
        idempotencyKey,
        jobOrOccurrenceId,
        paymentTransactionId,
    }: {
        paymentProvider: keyof common.ICustomerPaymentProvidersData | string;
        type: common.AutomaticPaymentTransactionSubType;
        customerId: string;
        amount: number;
        additionalReference: string;
        initiatorReference: 'auto' | 'confirmed';
        invoiceIds: string[];
        idempotencyKey: string;
        jobOrOccurrenceId?: string;
        paymentTransactionId?: string;
    }) {
        const occurrence = jobOrOccurrenceId && Data.get<common.JobOccurrence>(jobOrOccurrenceId);
        const services = occurrence && occurrence.services && occurrence.services.length ? occurrence.services : undefined;
        const serviceInfo = services ? Utilities.localiseList(services.map(s => s.description as TranslationKey)) : '';

        if (!additionalReference && !serviceInfo) additionalReference = `payment against balance`;

        const customPaymentMethods = ApplicationState.getSetting<Record<string, string | undefined>>('global.custom-payment-methods', {});
        const paymentProviderDesc = customPaymentMethods[paymentProvider] || paymentProvider;

        const paymentDescription =
            `${paymentProviderDesc} ${initiatorReference ? `(${initiatorReference})` : ''} ${serviceInfo ? ` ${serviceInfo}` : ''}` +
            `${additionalReference?.trim() !== serviceInfo?.trim() ? ' ' + additionalReference?.trim() : ''}`;

        const fullAuto = paymentProvider === 'gocardless' || paymentProvider === 'stripe' || initiatorReference === 'auto';

        const pmt = new common.PaymentTransaction(
            fullAuto ? common.TransactionType.AutomaticPayment : common.TransactionType.Payment,
            type,
            amount,
            paymentDescription,
            new common.PaymentDetails(
                paymentProvider,
                undefined,
                invoiceIds,
                undefined,
                undefined,
                ApplicationState.account.currency,
                idempotencyKey,
                TransactionService.getPaymentAccountIdForAutomaticPayment(paymentProvider)
            ),
            customerId,
            fullAuto ? 'awaitingApiHandling' : 'complete',
            undefined,
            undefined,
            undefined,
            paymentTransactionId
        );
        pmt.currency = ApplicationState.account.currency;
        return pmt;
    }

    public static getPaymentAccountIdForAutomaticPayment(paymentProvider: keyof common.ICustomerPaymentProvidersData | string) {
        // GC is represented by an internal payment account so all GC should be in it.
        if (paymentProvider === 'gocardless') return ApplicationState.dataEmail + '-gcpaymentaccount';
        if (paymentProvider === 'stripe')
            return `${ApplicationState.dataEmail}-stripepaymentaccount-${ApplicationState.account.currency || 'GBP'}`;
    }

    public static createManualPayment(
        type: common.PaymentTransactionSubType,
        method: common.PaymentMethod,
        customerId: string,
        amount: number,
        paymentDate: string = moment().format(),
        additionalReference?: string,
        invoiceIds: string[] = [],
        provider?: common.TransactionProvider,
        providerPaymentId?: string,
        paymentAccountId?: string
    ) {
        let paymentDescription = '';

        const paymentName = TransactionService.getPaymentMethodNameFromMethod(method);

        if (type.startsWith('payment.tip.')) {
            paymentDescription = additionalReference || '';
        } else {
            paymentDescription = paymentName + (additionalReference ? ' ref:' + additionalReference.trim() : '');
        }

        const pmtTran = new common.PaymentTransaction(
            common.TransactionType.Payment,
            type,
            amount,
            paymentDescription,
            new common.PaymentDetails(
                provider,
                undefined,
                invoiceIds,
                providerPaymentId,
                undefined,
                ApplicationState.account.currency,
                undefined,
                paymentAccountId
            ),
            customerId,
            'complete',
            paymentDate
        );

        if (provider && providerPaymentId) {
            if (!pmtTran.externalIds) pmtTran.externalIds = {};
            pmtTran.externalIds[provider] = providerPaymentId;
        }

        return pmtTran;
    }
    public static createRefund(
        type: common.RefundPaymentTransactionSubType,
        method: common.PaymentMethod,
        customerId: string,
        amount: number,
        paymentDate: string = moment().format(),
        additionalReference?: string,
        provider?: common.TransactionProvider,
        providerPaymentId?: string,
        payment?: Transaction,
        paymentAccountId?: string
    ) {
        const paymentName = TransactionService.getPaymentMethodNameFromMethod(method);
        const paymentDescription = paymentName + (additionalReference ? ' ref:' + additionalReference.trim() : '');

        const pmtTran = new common.RefundTransaction(
            type,
            amount,
            paymentDescription,
            new common.PaymentDetails(
                undefined,
                undefined,
                [],
                providerPaymentId,
                undefined,
                ApplicationState.account.currency,
                undefined,
                paymentAccountId
            ),
            customerId,
            'complete',
            paymentDate
        );

        if (provider && providerPaymentId) {
            if (!pmtTran.externalIds) pmtTran.externalIds = {};
            pmtTran.externalIds[provider] = providerPaymentId;
        }

        if (payment) pmtTran.paymentDetails.refundId = payment._id;

        return pmtTran;
    }
    public static createCreditNote(
        date: string,
        type: common.InvoiceTransactionSubType,
        customerId: string,
        amount: number,
        additionalReference: string,
        invoiceIds: Array<string> = []
    ) {
        const description =
            (type === 'invoice.credit-note' ? 'Credit Note' : 'Balance Adjustment') +
            (additionalReference ? String(' ref: ' + additionalReference.trim()) : '');

        const tran = new common.InvoiceTransaction(
            type,
            customerId,
            InvoiceService.createCreditInvoiceForCustomer(date, customerId, amount, additionalReference || '')
        );
        tran.description = description;
        tran.paymentDetails = new common.PaymentDetails(
            undefined,
            undefined,
            invoiceIds,
            undefined,
            undefined,
            ApplicationState.account.currency
        );
        return tran;
    }

    private static getPaymentMethodNameFromMethod(method: common.PaymentMethod): any {
        switch (method) {
            case common.PaymentMethod.cash:
                return ApplicationState.localise('payments.type-cash');
            case common.PaymentMethod.cheque:
                return ApplicationState.localise('payments.type-cheque');
            case common.PaymentMethod.bankTransfer:
                return ApplicationState.localise('payments.type-bank-transfer');
            case common.PaymentMethod.card:
                return ApplicationState.localise('payments.type-card');
            case common.PaymentMethod.directDebit:
                return ApplicationState.dd;
            case common.PaymentMethod.tip:
                return ApplicationState.localise('payments.type-tip');
            default:
                return ApplicationState.localise('payments.type-other');
        }
    }

    public static getCustomerPendingAutomaticTransactions(customerId: string) {
        return TransactionService.getCustomerTransactionsIncludingVoids(customerId).filter(transaction => {
            return (
                transaction.transactionType === common.TransactionType.AutomaticPayment &&
                transaction.status &&
                transaction.status === 'pending' &&
                !transaction.voidedId
            );
        });
    }

    public static async deleteOldCustomerStartingBalances(customerId: string) {
        const customerStartingBalanceTransactions = TransactionService.getCustomerStartingBalanceTransactions(customerId);
        let i = customerStartingBalanceTransactions.length;
        while (i--) {
            const transaction = customerStartingBalanceTransactions[i];
            await Data.delete(transaction);
        }
    }

    public static getCustomerStartingBalanceTransactions(customerId: string): Array<common.Transaction> {
        const customerTransactions = TransactionService.getCustomerTransactionsIncludingVoids(customerId);
        return customerTransactions.filter(transaction => {
            return transaction.transactionType === common.TransactionType.Adjustment && transaction.description === 'New customer balance';
        });
    }

    public static getCustomerStartingBalance(customerId: string): number {
        const customerStartingBalanceTransactions = TransactionService.getCustomerStartingBalanceTransactions(customerId);
        let startingBalance = 0;
        let i = customerStartingBalanceTransactions.length;
        while (i--) {
            const transaction = customerStartingBalanceTransactions[i];
            const value = Number(transaction.amount);
            startingBalance += value ? value : 0;
        }
        return common.to2dp(startingBalance);
    }
}
