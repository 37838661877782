import { useEffect, useState } from 'react';
import { Api } from '../../Server/Api';

export const useSqueegeeCredits = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [credits, setCredits] = useState<number>();

    useEffect(() => {
        const poll = async () => {
            try {
                setLoading(true);
                const response = await Api.getSqueegeeCredits();
                setCredits(() => response.squeegeeCredits);
                setLoading(false);
            } catch (e) {
                console.error(e, 'Error in useSqueegeeCredits');
            }
        };

        const timer = setInterval(() => {
            poll();
        }, 10000);
        poll();
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, []);

    return { loading, credits: credits ?? 0 };
};
