import { ListItem, ListItemText, Paper, Typography } from '@mui/material';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';

export const TodaysAppointmentSettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    return (
        <Paper sx={{ m: 2 }} variant="outlined">
            <ListItem>
                <ListItemText>
                    <Typography variant="h5">Todays appointment</Typography>
                </ListItemText>
            </ListItem>
        </Paper>
    );
};
