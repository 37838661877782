import { ThemeProvider as MuiThemeProvider, ScopedCssBaseline } from '@mui/material';
import type { Themes } from '@nexdynamic/nex-ui-react';
import { getTheme } from '@nexdynamic/nex-ui-react';
import React from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { useSetting } from '../hooks/useSetting';
type ContextType = {
    currentTheme: Themes;
    setTheme: (themeName: Themes) => void;
};

// const defaultMode = (): Themes => {
//     if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return 'light';
//     const mode = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light';
//     return mode;
// };

export const ThemeContext = React.createContext<ContextType>({
    currentTheme: 'light',
    setTheme: () => {
        //
    },
});

type ThemeProviderProps = {
    children: React.ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const [themeName, setThemeName] = useLocalStorage<Themes>('color-theme', 'light');
    const [themeMode] = useSetting('experiments.dark-mode', false);
    const theme = getTheme(themeMode ? 'dark' : 'light');

    const contextValue = {
        currentTheme: themeName || 'light',
        setTheme: setThemeName,
    };

    return (
        <ThemeContext.Provider value={contextValue}>
            <MuiThemeProvider theme={theme}>
                <ScopedCssBaseline />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
