import { Box, Grid, Paper, Typography } from '@mui/material';
import { DiscardButton, FormStep, GradientButton } from '@nexdynamic/nex-ui-react';
import type { TemplateCreateInput, TemplateState, TemplateUpdateInput } from '@nexdynamic/squeegee-common';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ApplicationState } from '../../../../../ApplicationState';
import useTranslation from '../../../..//hooks/useTranslation';
import TokenTextField from '../../../../components/inputs/TokenTextField';
import { getHTMLForTemplate } from '../TemplateService';
import { TemplateEditor } from './TemplateEditor';
import { TemplateSMSInput } from './TemplateSMSInput';
export class TemplateFormFields {
    name = '';
    state: TemplateState;
    smsTemplate = '';
    subject = '';
    includeLink = true;
    useSubjectAsSmsMessage = true;

    static toCreateInput({
        name,
        state,
        subject,
        smsTemplate,
        includeLink,
        useSubjectAsSmsMessage,
    }: TemplateFormFields): TemplateCreateInput {
        return {
            html: getHTMLForTemplate(state, name || ''),
            state,
            name,
            smsTemplate,
            subject,
            includeLink,
            useSubjectAsSmsMessage,
        };
    }

    static toUpdateInput({
        name,
        state,
        subject,
        smsTemplate,
        includeLink,
        useSubjectAsSmsMessage,
    }: Partial<TemplateFormFields>): TemplateUpdateInput {
        return {
            html: state ? getHTMLForTemplate(state, name || '') : undefined,
            state,
            name,
            smsTemplate,
            subject,
            includeLink,
            useSubjectAsSmsMessage,
        };
    }
}

type TemplateFormProps = {
    fields?: TemplateFormFields;
    onSubmit: (fields: TemplateFormFields) => Promise<void>;
};

const t = ApplicationState.localise;

const CreateTemplateSchema = () =>
    yup
        .object({
            name: yup.string().required(t('templates-form.nameRequired')),
            state: yup.object().required(t('templates-form.contentRequired')),
            subject: yup.string().required(t('templates-form.subjectRequired')),
        })
        .required();

const TemplateForm = ({ fields, onSubmit }: TemplateFormProps) => {
    const initialValues = fields ? fields : new TemplateFormFields();
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CreateTemplateSchema()}
            onSubmit={values => {
                return onSubmit(values);
            }}
        >
            {({ isSubmitting, values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                        <Box sx={{ flex: 1, mr: 1 }}>
                                            <Typography variant='h6'>{values.name ? values.name : 'Untitled Template'}</Typography>
                                        </Box>
                                        <Box>
                                            <DiscardButton sx={{ mr: 1 }} onDiscard={() => navigate(-1)} />
                                            <GradientButton
                                                aria-label={t('general.save')}
                                                text={t('general.save')}
                                                type='submit'
                                                disabled={isSubmitting}
                                                color='success'
                                            ></GradientButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['name', 'description']}
                                                title={t('templates-form.details')}
                                                description={t('templates-form.detailsDescription')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TextField}
                                                            type='text'
                                                            label={t('general.name')}
                                                            name='name'
                                                        />
                                                    </Box>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TokenTextField}
                                                            type='text'
                                                            label={t('templates-form.subject')}
                                                            name='subject'
                                                        />
                                                        <Typography variant='caption'>{t('templates-form.subjectDescription')}</Typography>
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['smsTemplate']}
                                                title={t('templates-form.smsStepTitle')}
                                                description={t('templates-form.smsStepDescription')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: '100%' }}>
                                                        <TemplateSMSInput fieldName='smsTemplate' />
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper>
                            <Box sx={{ p: 2 }}>
                                <TemplateEditor fieldName='state' />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default TemplateForm;
