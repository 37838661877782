import SentIcon from '@mui/icons-material/Send';
import { Box, Fade } from '@mui/material';
import { MultiSelectProvider } from '@nexdynamic/nex-ui-react';
import { useInboxActions } from '../hooks/useInboxActions';
import { useInboxMessages } from '../hooks/useInboxMessages';
import MailListContainer from './MailListContainer';
import { ScheduleMessagesHeader } from './ScheduledMessagesHeader';

export const ScheduledMessagesView = () => {
    const { actions } = useInboxActions();

    const { notifications, loadMore, hasMore, loading } = useInboxMessages({
        filters: {
            outbound: false,
            scheduled: true,
            inbound: false,
        },
        sortBy: (a, b) => (a.sendAtSecondsTimestamp || 0) - (b.sendAtSecondsTimestamp || 0),
    });

    return (
        <Fade in={true}>
            <Box>
                <ScheduleMessagesHeader />
                <MultiSelectProvider selectableItems={notifications} actions={actions}>
                    <MailListContainer
                        loading={loading}
                        hasMore={hasMore}
                        emptyText='inbox.scheduled-empty'
                        EmptyIcon={SentIcon}
                        notifications={notifications}
                        loadMore={loadMore}
                    />
                </MultiSelectProvider>
            </Box>
        </Fade>
    );
};
