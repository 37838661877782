import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import { ApplicationState } from '../../../ApplicationState';
import { CustomerService } from '../../../Customers/CustomerService';
import { UserService } from '../../../Users/UserService';
import type { AuditEntry } from '../types/AuditEntry';
dayjs.extend(advancedFormat);
dayjs.extend(calendar);

export const AuditListItem = ({
    entry,
    isExpanded,
    index,
    toggleExpandItem,
}: {
    entry: AuditEntry;
    isExpanded: boolean;
    dense?: boolean;
    index: number;
    toggleExpandItem: (index: number) => void;
}) => {
    const selectIcon = (action: string) => {
        if (action.includes('rejected')) return <ThumbDownOutlinedIcon />;
        if (action.includes('approved')) return <ThumbUpOutlinedIcon />;

        switch (action) {
            case 'delete':
                return <RemoveCircleOutlineIcon />;
            case 'transaction-void':
                return <BlockOutlinedIcon />;
            case 'test':
                return <ScienceOutlinedIcon />;
            case 'startup':
                return <PlayCircleOutlineIcon />;
            default:
                return <HelpOutlineOutlinedIcon />;
        }
    };

    const getUserNameAndAvatar = (email: string) => {
        const users = UserService.getUsers();
        const user = users.find(user => user.email === email);
        return { name: user?.name || email, avatar: user?.avatar || '' };
    };

    const getAction = (action: string) => {
        if (action.includes('rejected SMS name')) return 'rejected an SMS name request';
        if (action.includes('approved SMS name')) return 'approved an SMS name request';
        switch (action) {
            case 'delete':
                return 'deleted';
            case 'transaction-void':
                return 'voided';
            case 'test':
                return 'created';
            default:
                return action;
        }
    };

    const getSubject = (subjects: string) => {
        switch (subjects[1]) {
            case 'customers':
                return 'a customer';
            case 'transactions':
                return 'a transaction';
            case 'joboccurrences':
                return 'a job occurence';
            case 'linkers':
                return 'a linked item';
            case 'auditevents':
                return 'an audit event';
            case 'accountuser':
                return 'a user';
            case 'quotedjobs':
                return 'a quoted job';
            case 'applicationstate':
                return '';
            default:
                return subjects[1];
        }
    };

    const handleRestoreCustomer = () => {
        CustomerService.restoreDeletedCustomers(entry?.subject[0]);
    };

    const theme = useTheme();

    return (
        <Accordion
            sx={{
                'borderTop': `1px solid ${theme.palette.divider}`,
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                'background': 'background.paper',
                'height': '100%',
                'display': 'flex',
                'flexDirection': 'column',
                '& .MuiCollapse-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'center',
                    background: 'background.paper',
                    borderTop: `1px solid ${theme.palette.divider}`,
                },
            }}
            expanded={isExpanded}
            onChange={() => {
                toggleExpandItem(index);
            }}
            disableGutters
            square
        >
            <AccordionSummary sx={{ height: 60 }} expandIcon={<ExpandMoreIcon />}>
                <ListItem sx={{ height: 60 }} disablePadding>
                    <ListItemIcon sx={{ minWidth: 32 }}>{selectIcon(entry?.action)}</ListItemIcon>
                    <Avatar sx={{ width: 32, height: 32, mr: 1 }} src={getUserNameAndAvatar(entry.createdBy).avatar} />
                    <ListItemText
                        primary={`${getUserNameAndAvatar(entry.createdBy).name} ${getAction(entry.action)} ${getSubject(entry.subject)}`}
                        secondary={dayjs(entry.timestamp)
                            // TODO: fix locale for calendar plugin
                            .calendar(null, {
                                sameDay: '[Today at] H:mma',
                                lastDay: '[Yesterday at] H:mma',
                                lastWeek: '[Last] dddd [at] H:mma',
                                sameElse: 'DD/MM/YYYY',
                            })
                            .toString()}
                    ></ListItemText>
                </ListItem>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'center',
                    p: 2,
                }}
            >
                <Typography fontSize="inherit">
                    <b>Time:</b> {dayjs(entry?.timestamp).format('MMM Do, HH:mm:ss').toString()}
                </Typography>
                <Typography fontSize="inherit">
                    <b>User:</b> {entry?.createdBy}
                </Typography>
                <Typography fontSize="inherit">
                    <b>Action:</b> {entry?.action}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography fontSize="inherit">
                        <b>Subject:</b> {entry?.subject[1]}
                    </Typography>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                    {ApplicationState.stateFlags.devMode ? (
                        <Button
                            onClick={() => console.log(entry)}
                            variant="contained"
                            size="small"
                            color="secondary"
                            disableElevation
                            sx={{ mr: 1 }}
                        >
                            LOG OBJECT
                        </Button>
                    ) : null}
                    {entry?.subject[1] === 'customers' ? (
                        <Button onClick={handleRestoreCustomer} variant="contained" size="small" color="primary" disableElevation>
                            Restore
                        </Button>
                    ) : null}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
