import type { IAdvertisement, ICreateAdvertisementRequest, IGetAdvertisementsResponse, ISqueegeeClient } from '@nexdynamic/squeegee-common';
import { Alert } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../ApplicationState';
import { Data } from '../../../Data/Data';
import { Api } from '../../../Server/Api';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';

export async function createAdvertisement(request: ICreateAdvertisementRequest): Promise<{ advertisements: Array<IAdvertisement> }> {
    const result = await postReq<{ advertisements: Array<IAdvertisement> }>(`/api/advertisements/`, request);
    if (!result.advertisements?.length) return { advertisements: [] };
    for (const ad of result.advertisements) {
        const assetIds = ad.squeegeeAssets?.map(x => x.id) || [];
        if (assetIds.length) AdvertisementAssetCache.addItems(assetIds);
    }
    return result;
}

export async function updateAdvertisement(id: string, request: Partial<IAdvertisement>) {
    return await putReq(`/api/advertisements/${id}`, request);
}
export async function deleteAdvertisement(id: string) {
    return await deleteReq(`/api/advertisements/${id}`);
}

export async function getAdvertisements(query?: string): Promise<IGetAdvertisementsResponse> {
    return (await getReq(`/api/advertisements${query ? '?' + query : ''}`)) as IGetAdvertisementsResponse;
}

export async function cancelCheckout(id: string): Promise<IAdvertisement> {
    return await postReq(`/api/advertisements/${id}/cancel-checkout`);
}

export async function logAdvertEvent(id: string, logCode: 'ADVERTISEMENT_VIEWED', message: string) {
    return await postReq(`/api/advertisements/${id}/log-event`, { logCode, message });
}

export async function hasAdvertInArea(): Promise<boolean> {
    const distance = ApplicationState.getSetting('global.marketplace-receive-area', 15);
    const result = await Api.get<boolean>(null, `/api/advertisements-in-area/${distance}`);
    return !!result?.data;
}

export async function getMyAdverts(query?: string): Promise<{ advertisements: Array<IAdvertisement> }> {
    return (await getReq(`/api/my-advertisements${query ? '?' + query : ''}`)) as { advertisements: Array<IAdvertisement> };
}
export async function getMyPurchases(query?: string): Promise<{ advertisements: Array<IAdvertisement> }> {
    return (await getReq(`/api/my-purchases${query ? '?' + query : ''}`)) as { advertisements: Array<IAdvertisement> };
}

export const runAdvertChecker = async () => {
    const id = `${ApplicationState.account.uuid}-local-adverts`;
    if (!Data.get<Alert>(id)) {
        const hasAdverts = await hasAdvertInArea();
        if (hasAdverts) {
            const alert = new Alert('Marketplace', 'There are new adverts in your area, check them out now?', 'system', undefined, [
                {
                    action: ((sqg: ISqueegeeClient, alert: Alert) => {
                        sqg.ApplicationState.navigateToRouteFragment('marketplace/home');
                        sqg.Data.delete([alert]);
                    }).toString(),
                    icon: 'store',
                    name: `Go to Marketplace`,
                },
            ]);
            alert._id = id;

            Data.put(alert);
        }
    }

    setTimeout(runAdvertChecker, 900000);
};

export async function refreshCheckoutStatus(id: string): Promise<IAdvertisement> {
    const ok = await postReq<{ message: string } | { message: string; updated: IAdvertisement }>(
        `/api/my-advertisements/${id}/refresh-checkout`
    );
    if (!ok) throw new Error('Failed to refresh checkout status');

    if ('updated' in ok) {
        return ok.updated;
    } else {
        throw new Error(ok.message);
    }
}

export async function publishAdvertisement(id: string): Promise<void> {
    return await postReq(`/api/advertisements/${id}/publish`);
}

export async function unpublishAdvertisement(id: string): Promise<void> {
    return await postReq(`/api/advertisements/${id}/unpublish`);
}

export async function enableAccess() {
    return await getReq(`/api/marketplace/enable`);
}

export async function transferAdvertismentAssets(id: string) {
    return await postReq(`/api/my-advertisements/${id}/transfer`);
}

export async function transferFranchiseAssets(id: string) {
    return await postReq(`/api/my-advertisements/${id}/in-house-transfer`);
}

export async function getSecretForPaymentIntent(id: string) {
    const url = `/api/advertisements/${id}/checkout`;
    const data = await getReq(url);
    return data as { client_secret: string; termsHtml: string } | undefined;
}

export const sendMarketplaceTermsRequest = async (id: string, terms: string) => {
    return await postReq(`/api/marketplace/seller/${id}/terms-request`, { terms });
};

async function postReq<T>(url: string, data?: any) {
    const apiEndpoint = Api.apiEndpoint;
    const authorisationToken = RethinkDbAuthClient.getSessionKeyAndValue();
    const resp = await fetch(`${apiEndpoint}${url}`, {
        body: data && JSON.stringify(data),
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`,
        },
        method: 'POST',
    });
    if (!resp.ok) throw new Error((await resp.text()) || `error fetching from ${url}: ${resp.status}`);

    return (await resp.json()) as T;
}

async function deleteReq(url: string) {
    const apiEndpoint = Api.apiEndpoint;
    const authorisationToken = RethinkDbAuthClient.getSessionKeyAndValue();
    const resp = await fetch(`${apiEndpoint}${url}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`,
        },
        method: 'DELETE',
    });
    const respData = await resp.json();
    switch (resp.status) {
        case 200: {
            return respData;
        }
        default:
            throw new Error(respData.message || `Unknown error: ${resp.status}`);
    }
}

async function putReq(url: string, data: any) {
    const apiEndpoint = Api.apiEndpoint;
    const authorisationToken = RethinkDbAuthClient.getSessionKeyAndValue();
    const resp = await fetch(`${apiEndpoint}${url}`, {
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`,
        },
        method: 'PUT',
    });
    const respData = await resp.json();
    switch (resp.status) {
        case 200: {
            return respData;
        }
        default:
            throw new Error(respData.message || `Unknown error: ${resp.status}`);
    }
}

async function getReq<T>(url: string): Promise<T> {
    const apiEndpoint = Api.apiEndpoint;
    const authorisationToken = RethinkDbAuthClient.getSessionKeyAndValue();
    const resp = await fetch(`${apiEndpoint}${url}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${authorisationToken}`,
        },
        method: 'GET',
    });

    if (!resp.ok) throw new Error((await resp.text()) || `error fetching from ${url}: ${resp.status}`);
    return (await resp.json()) as T;
}
const assetIsListed = (id: string) => {
    return AdvertisementAssetCache.assetIds.includes(id);
};
const addItems = (assetIds: Array<string>) => {
    const newIds = assetIds.filter(id => !AdvertisementAssetCache.assetIds.includes(id));

    ApplicationState.setSetting('marketplace.items-listed', [...AdvertisementAssetCache.assetIds, ...newIds]).then(() => {
        AdvertisementAssetCache.assetIds = ApplicationState.getSetting('marketplace.items-listed', []);
    });
};
const removeItem = (assetId: string) => {
    ApplicationState.setSetting(
        'marketplace.items-listed',
        AdvertisementAssetCache.assetIds.filter(id => id !== assetId)
    );
};
export const AdvertisementAssetCache = {
    addItems,
    removeItem,
    assetIsListed,
    clear: () => ApplicationState.clearSetting('marketplace.items-listed'),
    assetIds: Array<string>(),
};
