import WarningIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, CircularProgress, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../../ApplicationState';
import { LoaderEvent } from '../../../../Events/LoaderEvent';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import useStateFlags from '../../../hooks/useStateFlags';
import useTranslation from '../../../hooks/useTranslation';
import PortalLayoutEditorDialog from '../../PortalEditor/PortalLayoutEditorMain';
import { usePortalLayout } from '../../PortalEditor/PortalLayoutProvider';
import { useDirectoryEntryProvider } from '../../useDirectoryEntryDetails';
import JsonEditorDialog from '../components/JsonEditor/JsonEditorDialog';
import OverviewPlaceholder from './OverviewPlaceholder';
import OverviewView from './OverviewView';
import SettingsView from './SettingsView';

export const PortalDirectoryMain = () => {
    const [activeTab, setActiveTab] = useState('overview');
    const [changesMade, setChangesMade] = useState(false);

    const handleChangesMade = (hasChanged: boolean, reason?: string) => {
        setChangesMade(hasChanged);
        if (reason) console.log('changes made set to', hasChanged, 'reason:', reason);
    };

    const { handleSaveLayout } = usePortalLayout();

    const { directory, saveDirectory, loadingDirectory } = useDirectoryEntryProvider();
    const [directoryEntry, setDirectoryEntry] = useState(directory);
    const originalDirectory = useRef(directory);
    const [jsonEditorOpen, setJsonEditorOpen] = useState(false);

    useEffect(() => {
        if (originalDirectory.current !== undefined) return;
        setDirectoryEntry(directory);
        originalDirectory.current = directory;
    }, [directory]);

    useEffect(() => {
        // Shows a confirmation dialog before closing the tab if there are unsaved changes
        const beforeUnload = (e: BeforeUnloadEvent) => {
            if (changesMade) {
                e.preventDefault();
                return true;
            }
            return undefined;
        };
        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [changesMade]);

    const handleDirectoryChange = (key: keyof IDirectoryEntryDetails, value: any) => {
        console.log('received change', key, value);
        setDirectoryEntry(prev => ({ ...prev, [key]: value } as IDirectoryEntryDetails));
    };

    const handleSave = async () => {
        if (!directoryEntry) return;
        new LoaderEvent(true);
        await handleSaveLayout();
        await saveDirectory(directoryEntry);
        new LoaderEvent(false);
        new NotifyUserMessage('directory.saved');
        handleChangesMade(false, 'save');
    };

    const handleJsonEditorSave = (json: IDirectoryEntryDetails) => {
        setDirectoryEntry(json);
        handleSave();
    };

    useEffect(() => {
        // i am mega confused, when i change service question hidden state,
        // it doesn't set changes made to true, also when I change the theme,
        // then change it back, it doesn't set changes made to false.
        const equal = JSON.stringify(originalDirectory.current) === JSON.stringify(directoryEntry);
        handleChangesMade(!equal, 'json compare');
    }, [directoryEntry]);

    const nav = useNavigate();
    const { t } = useTranslation();

    const { devMode } = useStateFlags();
    const isSmallScreen = useIsSmallScreen();

    return (
        <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', pt: 2, pb: 3, maxWidth: '100%' }}>
            {directoryEntry && !loadingDirectory ? (
                <>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        gap={2}
                        width="100%"
                    >
                        <Tabs
                            value={activeTab}
                            onChange={(_, newValue) => {
                                nav(`/portal-directory/${newValue === 'overview' ? '' : newValue}`);
                                setActiveTab(newValue);
                            }}
                        >
                            <Tab label="Overview" value="overview" />
                            {!isSmallScreen && <Tab label="Layouts" value="layouts" />}
                            <Tab label="Settings" value="settings" />
                        </Tabs>
                        {!isSmallScreen && (
                            <Stack direction="row" spacing={1}>
                                {changesMade && (
                                    <Box display="flex" alignItems="center" gap={0.5}>
                                        <WarningIcon color="warning" />
                                        <Typography fontWeight="bold">{t('warning.unsaved-changes')}</Typography>
                                    </Box>
                                )}
                                {devMode && <Button onClick={() => setJsonEditorOpen(true)}>Edit Config</Button>}
                                <Button href={`https://squeeg.ee/portal/${directory?.uniqueUrl}`} target="_blank">
                                    View
                                </Button>
                                {(activeTab === 'settings' || activeTab === 'layouts') && (
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                        {t(directoryEntry?.isPublic ? 'directory.publish' : 'directory.save')}
                                    </Button>
                                )}
                            </Stack>
                        )}
                        {(activeTab === 'settings' || activeTab === 'layouts') && isSmallScreen && (
                            <Button variant="contained" color="primary" onClick={handleSave} disabled={!changesMade}>
                                {t(directoryEntry?.isPublic ? 'directory.publish' : 'directory.save')}
                            </Button>
                        )}
                    </Stack>
                    <Routes>
                        <Route path="/" element={ApplicationState.hasUltimateOrAbove ? <OverviewView /> : <OverviewPlaceholder />} />
                        <Route path="/layouts" element={<PortalLayoutEditorDialog handleChangesMade={handleChangesMade} />} />
                        <Route
                            path="/settings"
                            element={
                                <SettingsView directory={directoryEntry} onDirectoryChange={handleDirectoryChange} save={saveDirectory} />
                            }
                        />
                    </Routes>
                </>
            ) : loadingDirectory ? (
                <Stack justifyContent="center" alignItems="center" height="100%" width="100%" spacing={1}>
                    <CircularProgress />
                    <Typography>Loading...</Typography>
                </Stack>
            ) : (
                <Typography>Error loading directory</Typography>
            )}
            <JsonEditorDialog
                open={jsonEditorOpen}
                onClose={() => setJsonEditorOpen(false)}
                baseJson={directoryEntry || {}}
                onSave={handleJsonEditorSave}
            />
        </Container>
    );
};

export default PortalDirectoryMain;
