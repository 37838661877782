import { Box, List } from '@mui/material';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import type { Template } from '@nexdynamic/squeegee-common';
import { useTranslation } from 'react-i18next';
import TemplateItem from './TemplateItem';
import TemplatesIcon from './TemplatesIcon';

type Props = {
    templates: Array<Template>;
    onSelect?: (template: Template) => void;
    hideActions?: boolean;
};

const TemplateGrid = ({ templates, onSelect, hideActions = false }: Props) => {
    const templateItems = [...templates.sort((a, b) => a.name.localeCompare(b.name))];
    const { t } = useTranslation();

    if (templateItems.length === 0)
        return (
            <List>
                <EmptyList icon={<TemplatesIcon />} text={t('templates.noTemplates')} />
            </List>
        );

    const items = templateItems.map(template => (
        <TemplateItem hideActions={hideActions} onSelect={onSelect} key={template._id} template={template} />
    ));

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gridGap: '16px' }}>
            {items.map(item => (
                <Box key={item.key}>{item}</Box>
            ))}
        </Box>
    );
};

export default TemplateGrid;
