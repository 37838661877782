import type { ISession } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { SqueegeeClientSocketApi } from './SqueegeeClientSocketApi';

export const getHeaders = (session?: ISession) => ({
    'Authorization': session ? `Bearer ${session.key}:${session.value}` : '',
    'data-email': ApplicationState.dataEmail || '',
    'client-version': ApplicationState.version,
    'client-id':
        (SqueegeeClientSocketApi.instance && SqueegeeClientSocketApi.instance.server && SqueegeeClientSocketApi.instance.server.clientId) ||
        '',
    'device-id': ApplicationState.deviceId,
});
