import type { BulkPriceChange, Customer, TranslationKey } from '@nexdynamic/squeegee-common';
import { changePrice, notNullUndefinedEmptyOrZero, to2dp } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../../../../ApplicationState';
import { Data } from '../../../../../Data/Data';
import { SendMessageToCustomer } from '../../../../../Dialogs/SendMessageToCustomer';
import { LoaderEvent } from '../../../../../Events/LoaderEvent';
import { Logger } from '../../../../../Logger';
import { NotifyUserMessage } from '../../../../../Notifications/NotifyUserMessage';
import type { PriceChangeMessageModel } from '../../../../../Schedule/PriceChangeMessageModel';
import { Utilities } from '../../../../../Utilities';
import { getAffectedJobsAndOccurrences } from '../views/jobs/getAffectedJobsAndOccurrences';

export const sendPriceChangeNotifications = async (
    bulkPriceChange: BulkPriceChange
): Promise<{ success: true; sent: { email: number; sms: number } } | { success: false; errorMsg?: TranslationKey; notify: boolean }> => {
    try {
        new LoaderEvent(true, true, 'price-change.compiling-notifications');
        if (!bulkPriceChange.affectedJobFilter) return { success: false, errorMsg: 'price-change.no-filters-applied', notify: true };

        if (bulkPriceChange.result && !bulkPriceChange.result.success) return { success: false, errorMsg: 'general.error', notify: true };

        const models: Record<string, { [name: string]: string | number | boolean }> = {};
        let customers: Array<Customer>;
        const formattedPlannedDate = moment(bulkPriceChange.plannedDate).format('ll');
        if (!bulkPriceChange.result) {
            const jobs = getAffectedJobsAndOccurrences(bulkPriceChange.affectedJobFilter);
            for (const x of jobs) {
                const customer = Data.get<Customer>(x.job.customerId);
                if (!customer) continue;

                const oldPrice = x.job.price;
                if (!oldPrice) continue;

                const newPrice = changePrice(oldPrice, bulkPriceChange.type);
                const difference = newPrice - oldPrice;
                const additionalModelProperties: PriceChangeMessageModel = {
                    formattedPlannedDate,
                    formattedOldPrice: `${ApplicationState.currencySymbol()}${to2dp(oldPrice).toFixed(2)}`,
                    formattedNewPrice: `${ApplicationState.currencySymbol()}${to2dp(newPrice).toFixed(2)}`,
                    formattedDifference: `${ApplicationState.currencySymbol()}${to2dp(difference).toFixed(2)}`,
                    jobAddress: x.job.location?.addressDescription || '',
                    serviceList: Utilities.localiseList(x.job.services.map(s => s.description as TranslationKey)),
                };
                const model = await Utilities.getStandardMessageModel({
                    customer,
                    isHtml: true,
                    templateText: ApplicationState.messagingTemplates.emailPriceChange,
                    additionalModelProperties,
                });
                models[x.job.customerId] = model;
            }

            customers = jobs.map(x => Data.get<Customer>(x.job.customerId)).filter(notNullUndefinedEmptyOrZero);
        } else {
            const jobIdToCustomerId = new Map<string, string>();
            for (const customer of Data.all<Customer>('customers')) {
                for (const jobId in customer.jobs) {
                    jobIdToCustomerId.set(jobId, customer._id);
                }
            }
            customers = [];
            for (const change of bulkPriceChange.result.changes) {
                const customerId = jobIdToCustomerId.get(change.jobId);
                if (!customerId) continue;

                const customer = Data.get<Customer>(customerId);
                if (!customer) continue;

                const job = customer.jobs[change.jobId];
                if (!job) continue;

                customers.push(customer);

                const oldPrice = change.oldPrice;
                const newPrice = change.newPrice;
                const difference = newPrice - oldPrice;
                const jobAddress = job.location?.addressDescription || '';
                const serviceList = Utilities.localiseList(job.services.map(s => s.description as TranslationKey));

                const additionalModelProperties: PriceChangeMessageModel = {
                    formattedPlannedDate,
                    formattedOldPrice: `${ApplicationState.currencySymbol()}${to2dp(oldPrice).toFixed(2)}`,
                    formattedNewPrice: `${ApplicationState.currencySymbol()}${to2dp(newPrice).toFixed(2)}`,
                    formattedDifference: `${ApplicationState.currencySymbol()}${to2dp(difference).toFixed(2)}`,
                    jobAddress,
                    serviceList,
                };

                const model = await Utilities.getStandardMessageModel({
                    customer,
                    isHtml: true,
                    templateText: ApplicationState.messagingTemplates.emailPriceChange,
                    additionalModelProperties,
                });

                models[customerId] = model;
            }
        }

        new LoaderEvent(false);

        const messageDialog = new SendMessageToCustomer(
            customers,
            {
                sms: ApplicationState.messagingTemplates.smsPriceChange,
                email: ApplicationState.messagingTemplates.emailPriceChange,
                emailIsHtml: true,
            },
            undefined,
            undefined,
            undefined,
            models
        );

        await messageDialog.show();

        if (messageDialog.cancelled) return { success: false, notify: false };

        if (messageDialog.sent.email > 0) {
            new NotifyUserMessage('price-change.notified-email', { email: messageDialog.sent.email.toString() });
        }
        if (messageDialog.sent.sms > 0) {
            new NotifyUserMessage('price-change.notified-sms', { sms: messageDialog.sent.sms.toString() });
        }

        bulkPriceChange.notified = true;
        Data.put(bulkPriceChange);
        return { success: true, sent: messageDialog.sent };
    } catch (error) {
        Logger.error('Failed to notify customers of price change', { error });
        return { success: false, errorMsg: 'price-change.notify-failed', notify: true };
    } finally {
        new LoaderEvent(false);
    }
};
