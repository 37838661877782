import { Box, Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material';
import type { Template } from '@nexdynamic/squeegee-common';
import TemplateActions from './TemplateActions';

type Props = {
    template: Template;
    hideActions?: boolean;
    onSelect?: (template: Template) => void;
};

const CardWrapper = ({
    children,
    onSelect,
    template,
}: {
    template: Template;
    children: React.ReactNode;
    onSelect?: (template: Template) => void;
}) => {
    if (onSelect) {
        return (
            <CardActionArea sx={{ height: '100%' }} onClick={() => onSelect(template)}>
                {children}
            </CardActionArea>
        );
    }
    return <>{children}</>;
};

const TemplateItem = ({ template, hideActions = false, onSelect }: Props) => {
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardWrapper template={template} onSelect={onSelect}>
                <CardContent
                    sx={{
                        flex: 1,
                    }}
                >
                    <Typography variant='h6'>{template.name}</Typography>
                    <Typography
                        sx={{
                            wordWrap: 'break-word',
                            display: '-webkit-box',
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {template.subject}
                    </Typography>
                </CardContent>
            </CardWrapper>
            {!hideActions && (
                <Box sx={{ display: 'flex', px: 2, alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}></Box>

                    <CardActions sx={{ justifyContent: 'flex-end', flexWrap: 'wrap', pr: 0 }}>
                        <TemplateActions template={template} />
                    </CardActions>
                </Box>
            )}
        </Card>
    );
};

export default TemplateItem;
