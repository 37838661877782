import type {
    Audience,
    CampaignCreateInput,
    CampaignUpdateInput,
    Customer,
    Notification,
    NotificationStatus,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Campaign,
    CampaignDeliveryReport,
    CampaignDeliveryType,
    CampaignStatus,
    NotificationWithCampaign,
    Template,
    calculateSqueegeeCreditsRequiredForSms,
    notNullUndefinedEmptyOrZero,
    replaceMessageTokensWithModelValues,
    searchCustomers,
} from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../ApplicationState';
import { Data } from '../../../../Data/Data';
import { Prompt } from '../../../../Dialogs/Prompt';
import { LoaderEvent } from '../../../../Events/LoaderEvent';
import { SendNotificationService } from '../../../../Notifications/SendNotificationService';
import { purchaseSqueegeeCredits } from '../../../../Notifications/Utils/purchaseSqueegeeCredits';
import { attachSendCustomerCampaignMessageLink } from '../../../../Notifications/Utils/smsAttachLinkToMessage';
import { Api } from '../../../../Server/Api';
import { RethinkDbAuthClient } from '../../../../Server/RethinkDbAuthClient';
import { Utilities, hasMobile } from '../../../../Utilities';
import { t } from '../../../../t';
import { audienceGetContacts } from '../audiences/AudienceService';

export const campaignRemove = async (campaign: Campaign) => {
    if (campaign.campaignDeliveryReportId) {
        const report = Data.get<CampaignDeliveryReport>(campaign.campaignDeliveryReportId);
        if (report) await Data.delete([report]);
    }
    /**
     * Delete the revised template of the campaign if it exists
     */
    if (campaign.templateId?.includes('rev-')) {
        const template = Data.get<Template>(campaign.templateId);
        if (template) await Data.delete([template]);
    }

    await Data.delete([campaign]);
};

export const campaignCreate = async (input: CampaignCreateInput) => {
    const campaign: Campaign = {
        ...new Campaign(),
        ...input,
    };
    const deliveryReport = new CampaignDeliveryReport(campaign._id);

    campaign.audiences = input.audienceIds;
    campaign.campaignDeliveryReportId = deliveryReport._id;

    await Data.put([campaign, deliveryReport]);

    return campaign;
};

const checkHasMarketingPermission = async (campaign: Campaign, contacts: Customer[]): Promise<boolean> => {
    const hasMarketingFilter = campaign.audiences
        .map(id => Data.get<Audience>(id))
        .filter(notNullUndefinedEmptyOrZero)
        .some(a => a.filterGroups.some(fg => fg.some(f => f.field === 'optedInToMarketing')));

    if (!hasMarketingFilter) {
        const atLeastOneCustomerHasMarketingDisabled = contacts.filter(contact => !contact.marketingAccepted).length > 0;
        if (atLeastOneCustomerHasMarketingDisabled) {
            const checkPrompt = new Prompt('general.warning', 'send.sending-to-non-marketing-accepted-customers-warning', {
                okLabel: 'general.continue',
                cancelLabel: 'general.cancel',
            });

            await checkPrompt.show();

            if (checkPrompt.cancelled) return false;
        }
    }

    return true;
};

const checkHasEnoughCredits = async <T extends { message: string }>(
    notifications: Array<T>
): Promise<{
    creditsNeeded: number;
    hasEnoughCredits: boolean;
}> => {
    new LoaderEvent(true, true, 'campaigns.checking-credits' as TranslationKey);
    try {
        const { squeegeeCredits } = await Api.getSqueegeeCredits();

        const totalCreditsNeededToSendThisCampaign = notifications.reduce((total, notification) => {
            const { credits } =
                typeof notification.message === 'string'
                    ? calculateSqueegeeCreditsRequiredForSms(notification.message, true)
                    : { credits: 0 };
            total += credits;
            return total;
        }, 0);

        const autoTopupLevel = ApplicationState.account.smsAutoTopup ? ApplicationState.account.smsAutoTopupTrigger || 0 : 0;
        const remainingCredits = squeegeeCredits - totalCreditsNeededToSendThisCampaign - autoTopupLevel;
        let creditsNeeded = remainingCredits < 0 ? Math.abs(remainingCredits) : 0;

        // Minimum of 100 or auto topup amount
        let minimumPurchase =
            (ApplicationState.account.smsAutoTopup &&
                ApplicationState.account.smsAutoTopupTrigger &&
                ApplicationState.account.smsAutoTopupAmount) ||
            100;

        if (minimumPurchase < 100) minimumPurchase = 100;

        if (creditsNeeded > 0 && creditsNeeded < minimumPurchase) creditsNeeded = minimumPurchase;
        return {
            creditsNeeded,
            hasEnoughCredits: creditsNeeded === 0,
        };
    } finally {
        new LoaderEvent(false);
    }
};

export const checkHasEnoughCreditsToPublish = async <T extends { message: string }>(notifications: Array<T>): Promise<boolean> => {
    const { hasEnoughCredits, creditsNeeded } = await checkHasEnoughCredits(notifications);
    if (hasEnoughCredits) return true;

    const prompt = new Prompt('general.warning', 'campaigns.not-enough-credits-to-publish', {
        okLabel: 'general.purchase-credits',
        localisationParams: { amount: creditsNeeded.toString() },
    });

    const purchaseMore = await prompt.show();
    if (prompt.cancelled || purchaseMore === false) return false;

    // Purchase more credits
    const { purchaseComplete } = await purchaseSqueegeeCredits({ amount: creditsNeeded });
    if (purchaseComplete) return true;
    return false;
};

export const campaignPublish = async (campaign: Campaign): Promise<{ success: boolean; errors?: Array<TranslationKey> }> => {
    const oldTemplate = campaign.templateId ? Data.get<Template>(campaign.templateId) : undefined;
    if (!oldTemplate) return { success: false, errors: ['campaigns.errors-template-is-required'] };

    if (RethinkDbAuthClient.session?.unverified !== false) {
        return { success: false, errors: ['notification.account-email-not-verified-message-sending'] };
    }

    if (campaign.publishedDate) return { success: false, errors: ['campaigns.errors-alread-published'] };

    // Check the campaign still has an audience
    if (!campaign.audiences.length) {
        return { success: false, errors: ['campaigns-errors.publish-no-audiences'] };
    }

    const contacts = campaign.audiences.reduce(
        (contacts, audienceId) => [...contacts, ...audienceGetContacts(audienceId)],
        [] as Array<Customer>
    );

    const grantedPermission = await checkHasMarketingPermission(campaign, contacts);

    if (!grantedPermission) {
        return { success: false, errors: ['notifications.cant-send-campaign-to-non-marketing'] };
    }

    // Create a new template revision for this campaign so it can't be changed
    const { existingTemplate, revisedTemplate } = Template.revision(oldTemplate);

    const notifications: Array<NotificationWithCampaign> = [];

    if (!campaign.campaignDeliveryReportId) throw new Error('Missing campaignDeliveryReportId');
    const deliveryReport = Data.get<CampaignDeliveryReport>(campaign.campaignDeliveryReportId);
    if (!deliveryReport) throw new Error('Unable to find delivery report');

    const contactsSentTo: Array<string> = [];

    const sendViaEmail = campaign.deliveryMethods?.includes(CampaignDeliveryType.Email);
    const sendViaSMS = campaign.deliveryMethods?.includes(CampaignDeliveryType.Sms);

    for (const contact of contacts) {
        if (sendViaEmail && contact.email) {
            const notification = await createEmailNotification(campaign, contact, revisedTemplate);
            notifications.push(notification);
        }

        if (sendViaSMS && hasMobile(contact.telephoneNumber) && revisedTemplate.smsTemplate) {
            const notification = await createSmsNotification(campaign, contact, revisedTemplate);
            notifications.push(notification);
        }

        contactsSentTo.push(contact._id);
    }

    if (sendViaSMS) {
        const smsNotifications = notifications.filter(
            (n): n is NotificationWithCampaign & { message: string } => n.type === 'SMS' && typeof n.message === 'string'
        );
        const hasEnough = await checkHasEnoughCreditsToPublish(smsNotifications);
        if (!hasEnough) return { success: false, errors: ['campaigns.error-not-enough-credits'] };
    }

    await campaignUpdate(campaign._id, {
        templateId: revisedTemplate._id,
        contacts: contactsSentTo,
        publishedDate: Date.now(),
    });

    await Data.put([existingTemplate, revisedTemplate, ...notifications, deliveryReport], true, 'lazy');

    return { success: true };
};

const createEmailNotification = async (campaign: Campaign, contact: Customer, template: Template) => {
    if (!contact.email) throw new Error('No email for contact');
    const model = await Utilities.getStandardMessageModel({
        customer: contact,
        isHtml: true,
        templateText: template.html,
    });

    model.unsubscribeUrl = Utilities.getUnsubscribeUrl(contact._id);

    // TODO: This will get the link on the end of the email but it needs to be better integrated.
    const emailSubject = replaceMessageTokensWithModelValues({
        model,
        message: template.subject,
        options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
    });

    const notification = new NotificationWithCampaign({
        description: emailSubject,
        address: contact.email,
        addressee: contact._id,
        type: 'Email',
        sender: SendNotificationService.notificationFrom,
        message: '',
        customerId: contact._id,
        storedTemplateId: template._id,
        campaignId: campaign._id,
        model,
    });

    return notification;
};

const createSmsNotification = async (campaign: Campaign, contact: Customer, template: Template) => {
    if (!contact.telephoneNumber) throw new Error('No telephone number for contact');

    const model = await Utilities.getStandardMessageModel({
        customer: contact,
        isHtml: true,
        templateText: template.smsTemplate,
    });

    model.unsubscribeUrl = Utilities.getUnsubscribeUrl(contact._id);

    const message = replaceMessageTokensWithModelValues({
        model,
        message: template.smsTemplate,
        options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
    });

    const notification = new NotificationWithCampaign({
        description: template.subject,
        address: contact.telephoneNumber,
        addressee: contact._id,
        type: 'SMS',
        sender: SendNotificationService.notificationFrom,
        message,
        campaignId: campaign._id,
        customerId: contact._id,
        storedTemplateId: template._id,
        model,
    });

    if (!template.includeLink) return notification;

    // Include the link in the message.
    notification.message = attachSendCustomerCampaignMessageLink({ message, notificationId: notification._id });
    return notification;
};

export const campaignUpdate = async (campaignId: string, input: CampaignUpdateInput) => {
    const currentCampaign = Data.get<Campaign>(campaignId);

    if (currentCampaign) {
        const campaign: Campaign = {
            ...currentCampaign,
            ...input,
        };

        if (!currentCampaign.publishedDate && input.publishedDate) {
            campaign.publishedDate = input.publishedDate;
            campaign.status = CampaignStatus.Published;
        }

        if (input.audienceIds) campaign.audiences = input.audienceIds;

        await Data.put<Campaign>(campaign);

        return campaign;
    }
};

export const campaignGetContacts = (campaignId: string, paging?: { take: number; skip: number }, searchText?: string) => {
    const campaign = Data.get<Campaign>(campaignId);

    let contacts: Array<Customer> = [];

    for (const contact of campaign?.contacts || []) {
        const customer = Data.get<Customer>(contact);
        if (customer) contacts.push(customer);
    }

    if (searchText) {
        contacts = searchCustomers({ customers: contacts, searchText, searchJobs: true });
    }

    contacts.sort((a, b) => a.name.localeCompare(b.name));

    if (paging) {
        return contacts.slice(paging.skip, paging.skip + paging.take);
    }

    return contacts;
};

export const refreshCampaignDeliveryStats = (campaignId?: string) => {
    const campaignDeliveryReports = Data.all<CampaignDeliveryReport>('campaignDeliveryReports');
    for (const campaignDeliveryReport of campaignDeliveryReports) {
        if (campaignId && campaignDeliveryReport.campaignId !== campaignId) continue;

        const campaignNotifications = Data.all<Notification>('notifications', { campaignId: campaignDeliveryReport.campaignId });
        const sms: Partial<Record<NotificationStatus, number>> = {};
        const email: Partial<Record<NotificationStatus, number>> = {};
        for (const campaignNotification of campaignNotifications.filter(x => x.type === 'SMS' || x.type === 'Email')) {
            if (!campaignNotification.statusHistory) continue;
            const type = campaignNotification.type.toLowerCase();
            const stats = type === 'sms' ? sms : email;
            const hasDelivered = campaignNotification.statusHistory?.some(
                x => x.status === 'delivered' || x.status === 'open' || x.status === 'click'
            );
            let hasFailed = false;
            const statusesSeen = new Set<string>();
            for (const s of campaignNotification.statusHistory?.filter(x => x.status) || []) {
                if (statusesSeen.has(s.status)) continue;
                switch (s.status) {
                    case 'click':
                        stats.click = (stats.click || 0) + 1;
                        statusesSeen.add('click');

                        if (!statusesSeen.has('open')) {
                            stats.open = (stats.open || 0) + 1;
                            statusesSeen.add('open');
                        }

                        if (!statusesSeen.has('delivered')) {
                            stats.delivered = (stats.delivered || 0) + 1;
                            statusesSeen.add('delivered');
                        }

                        if (!statusesSeen.has('sent')) {
                            stats.sent = (stats.sent || 0) + 1;
                            statusesSeen.add('sent');
                        }

                        break;
                    case 'open':
                        stats.open = (stats.open || 0) + 1;
                        statusesSeen.add('open');

                        if (!statusesSeen.has('delivered')) {
                            stats.delivered = (stats.delivered || 0) + 1;
                            statusesSeen.add('delivered');
                        }

                        if (!statusesSeen.has('sent')) {
                            stats.sent = (stats.sent || 0) + 1;
                            statusesSeen.add('sent');
                        }
                        break;
                    case 'delivered':
                        stats.delivered = (stats.delivered || 0) + 1;
                        statusesSeen.add('delivered');

                        if (!statusesSeen.has('sent')) {
                            stats.sent = (stats.sent || 0) + 1;
                            statusesSeen.add('sent');
                        }

                        break;
                    case 'sent':
                        stats.sent = (stats.sent || 0) + 1;
                        statusesSeen.add('sent');

                        break;
                    case 'failed':
                    case 'bounce':
                    case 'dropped':
                    case 'spamreport':
                        if (!hasDelivered) {
                            stats.failed = (stats.failed || 0) + 1;
                            hasFailed = true;
                        }

                        if (s.status === 'failed') {
                            statusesSeen.add('failed');
                        } else {
                            stats[s.status] = (stats[s.status] || 0) + 1;
                            statusesSeen.add(s.status);
                        }

                        break;
                    default:
                        stats[s.status] = (stats[s.status] || 0) + 1;
                        statusesSeen.add(s.status);

                        break;
                }
            }
            if (!hasDelivered && campaignNotification.status !== 'failed' && hasFailed) {
                campaignNotification.status = 'failed';
                Data.put(campaignNotification);
            }
        }
        campaignDeliveryReport.sms = sms;
        campaignDeliveryReport.email = email;
        Data.put(campaignDeliveryReport);
    }
};

export const refreshNotificationStatuses = () => {
    const sms = Data.all<Notification>('notifications', { type: 'SMS' });
    const email = Data.all<Notification>('notifications', { type: 'Email' });
    for (const notification of [...sms, ...email]) {
        if (!notification.statusHistory) continue;
        const seen = new Set<NotificationStatus>();
        for (const s of notification.statusHistory?.filter(x => x.status) || []) {
            if (seen.has(s.status)) continue;
            switch (s.status) {
                case 'click':
                    seen.add('click');
                    seen.add('open');
                    seen.add('delivered');
                    seen.add('sent');
                    break;
                case 'open':
                    seen.add('open');
                    seen.add('delivered');
                    seen.add('sent');
                    break;
                case 'delivered':
                    seen.add('delivered');
                    seen.add('sent');
                    break;
                case 'sent':
                    seen.add('sent');
                    break;
                case 'failed':
                case 'bounce':
                case 'dropped':
                case 'spamreport':
                    seen.add('failed');
                    break;
                default:
                    break;
            }
        }

        // WTF This is the required order for the status to be correct, don't mess with it!
        let updated = false;
        if (seen.has('click') && notification.status !== 'click') {
            notification.status = 'click';
            updated = true;
        } else if (seen.has('open') && notification.status !== 'open') {
            notification.status = 'open';
            updated = true;
        } else if (seen.has('delivered') && notification.status !== 'delivered') {
            notification.status = 'delivered';
            updated = true;
        } else if (seen.has('failed') && notification.status !== 'failed') {
            notification.status = 'failed';
            updated = true;
        } else if (seen.has('sent') && notification.status !== 'sent') {
            notification.status = 'sent';
            updated = true;
        }

        if (updated) Data.put(notification);
    }
};
