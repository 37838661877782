
export class PriceChangeMessageModel {
    [name: string]: string;
    jobAddress = '';
    serviceList = '';
    formattedPlannedDate = '';
    formattedOldPrice = '';
    formattedNewPrice = '';
    formattedDifference = '';
}
