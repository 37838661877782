import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { Template } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import TemplateForm, { TemplateFormFields } from '../components/TemplateForm';
import useUpdateTemplate from '../hooks/useUpdateTemplate';

const EditTemplate = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { updateTemplate } = useUpdateTemplate(id);

    const template = useStoredObject<Template>(id);

    const onSubmit = async (fields: TemplateFormFields) => {
        const template = await updateTemplate(TemplateFormFields.toUpdateInput(fields));
        if (template) {
            navigate(-1);
        }
    };

    if (!template) return <Typography>Template not found</Typography>;

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label='breadcrumb'>
                    <Link sx={{ cursor: 'pointer' }} component='button' underline='none' onClick={() => navigate('/send/templates')}>
                        <Text variant='subtitle1' text='templates.title' />
                    </Link>
                    <Typography variant='subtitle1'>{template?.name}</Typography>
                    <Text variant='subtitle1' color='text.primary' text='general.edit' />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ pb: 10 }}>
                    <TemplateForm onSubmit={onSubmit} fields={{ ...template }} />
                </Box>
            </Grid>
        </PageContainer>
    );
};

export default EditTemplate;
