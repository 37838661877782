import { Box, Breadcrumbs, Grid, Link } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router';
import Text from '../../../../components/Text';
import TemplateForm, { TemplateFormFields } from '../components/TemplateForm';
import { useCreateTemplate } from '../hooks/useCreateTemplate';

const CreateTemplate = () => {
    const navigate = useNavigate();
    const { createTemplate } = useCreateTemplate();

    const onSubmit = async (fields: TemplateFormFields) => {
        const template = await createTemplate(TemplateFormFields.toCreateInput(fields));
        if (template) {
            navigate(-1);
        }
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label='breadcrumb'>
                    <Link sx={{ cursor: 'pointer' }} component='button' underline='none' onClick={() => navigate('/send/templates')}>
                        <Text variant='subtitle1' text='templates.title' />
                    </Link>
                    <Text variant='subtitle1' color='text.primary' text='templates.createAction' />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ pb: 10 }}>
                    <TemplateForm onSubmit={onSubmit} />
                </Box>
            </Grid>
        </PageContainer>
    );
};

export default CreateTemplate;
