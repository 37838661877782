import type { Campaign, TemplateCreateInput, TemplateState, TemplateUpdateInput } from '@nexdynamic/squeegee-common';
import { Template } from '@nexdynamic/squeegee-common';
import toHTML from 'draftjs-to-html';
import { Data } from '../../../../Data/Data';
import templateStyles from '../../TemplateStyles';

export const templateCreate = async (fields: TemplateCreateInput): Promise<Template> => {
    const template = {
        ...new Template(),
        ...fields,
    };

    await Data.put<Template>(template);

    return template;
};

export const templateRemove = async (template: Template): Promise<void> => {
    const campaignsToUpdate = Data.all<Campaign>('campaigns', { templateId: template._id }).map(c => {
        c.templateId = undefined;
        return c;
    })
    await Data.put<Campaign>(campaignsToUpdate);
    await Data.delete([template]);
};

export const templateUpdate = async (id: string, fields: TemplateUpdateInput): Promise<Template> => {
    const currentTemplate = Data.get<Template>(id);

    if (currentTemplate) {
        const template = {
            ...currentTemplate,
            ...fields,
        };

        await Data.put<Template>(template);

        return template;
    } else {
        throw new Error('Template not found');
    }
};

const customEntityRender = (entity: { type: string, mutalibity: string }, text: string) => {
    if (entity.type === 'MENTION') {
        return `<span>${text}</span>`;
    }
}

export const getHTMLForTemplate = (state: TemplateState, title: string): string => {

    const content = toHTML(state, undefined, undefined, customEntityRender);

    return `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <meta name="x-apple-disable-message-reformatting">
        <title>${title}</title>
        <style>${templateStyles.styles}</style>
    </head>

    <body>
        <div class="content-wrapper">
            <div class="content">
                ${content}
            </div>
        </div>

    <div class="unsubscribe-link">
        <center>
            <small><a href="[unsubscribeUrl]" target="_blank">Unsubscribe</a></small>
        </center>
     <div/>

    </body>
</html>
    `;
}