import { FormHelperText } from '@mui/material';
import type { TemplateState } from '@nexdynamic/squeegee-common';
import { convertFromRaw, EditorState } from 'draft-js';
import { ErrorMessage, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import RichText from '../../../../components/editor/RichText';
import type { TemplateFormFields } from './TemplateForm';

type TemplateEditorProps = {
    fieldName: string;
};

export const TemplateEditor = ({ fieldName }: TemplateEditorProps) => {
    const { setFieldTouched, initialValues, setFieldValue } = useFormikContext<TemplateFormFields>();

    const updateValue = useCallback(
        async (state: TemplateState) => {
            setFieldTouched(fieldName, true);
            setFieldValue(fieldName, state);
        },
        [fieldName, setFieldTouched, setFieldValue]
    );

    const initialValue = initialValues[fieldName as keyof TemplateFormFields] as TemplateState;

    return useMemo(
        () => (
            <>
                <RichText
                    initialState={initialValue ? EditorState.createWithContent(convertFromRaw(initialValue)) : undefined}
                    onChange={updateValue}
                />
                <FormHelperText error={true}>
                    <ErrorMessage name={fieldName} />
                </FormHelperText>
            </>
        ),
        [initialValue, fieldName, updateValue]
    );
};
