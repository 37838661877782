import type { ComponentNames } from '@nexdynamic/squeegee-portal-common';
import type { AureliaReactComponentDialogComponent } from '../../../Dialogs/AureliaReactComponentDialogComponent';
import { AppointmentHistorySettingsEditor } from './SettingComponentEditors/AppointmentHistorySettingsEditor';
import { BookAppointmentSettingsEditor } from './SettingComponentEditors/BookAppointmentSettingsEditor';
import { EditProfileSettingsEditor } from './SettingComponentEditors/EditProfileSettingsEditor';
import { PortalChatSettingsEditor } from './SettingComponentEditors/PortalChatSettingsEditor';
import { ProfileSettingsEditor } from './SettingComponentEditors/ProfileSettingsEditor';
import SignInComponentSettings from './SettingComponentEditors/SignInComponentSettings';
import UpcomingAppointmentSettingsEditor from './SettingComponentEditors/UpcomingAppointmentSettingsEditor';

export const settingEditors: Record<ComponentNames, AureliaReactComponentDialogComponent | null> = {
    'address-search': null,
    'all-quotes': null,
    'appointment-history': AppointmentHistorySettingsEditor,
    'automatic-payments': null,
    'book-appointment': BookAppointmentSettingsEditor,
    'chat': PortalChatSettingsEditor,
    'component-previewer': null,
    'edit-profile-button': EditProfileSettingsEditor,
    'financial-history': null,
    'layout': null,
    'my-ratings': null,
    'profile': ProfileSettingsEditor,
    'quote': null,
    'sign-in': SignInComponentSettings,
    'sign-out': null,
    'spacer': null,
    'stripe-pre-pay': null,
    // 'todays-appointment': TodaysAppointmentSettingsEditor,
    'todays-appointment': null,
    'welcome-card': null,
    'quote-flow': null,
    'upcoming-appointments': UpcomingAppointmentSettingsEditor,
} as const;
