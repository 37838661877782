import type { Template } from '@nexdynamic/squeegee-common';
import useStoredObjects from '../../../../hooks/useStoredObjects';

class Options {
    includeReadonly = false;
}

type UseTemplates = (options?: Options) => Template[];

const useTemplates: UseTemplates = ({ includeReadonly } = new Options()) => {
    const templates = useStoredObjects<Template>('templates', { sort: (a, b) => a.name.localeCompare(b.name) });
    return includeReadonly ? templates : templates.filter(t => !t.readonly);
};

export default useTemplates;
