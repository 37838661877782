import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import AppProviders from '../AppProviders';
import { SqueegeeBalance } from '../balance/SqueegeeBalance';
import ApplicationStateProvider from '../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../components/BrowserFallBackToHashRouter';
import Text from '../components/Text';
import ViewLoading from '../components/ViewLoading';
import SuppliersRoutes from './views/SuppliersRoutes';

export const Suppliers = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <SqueegeeBalance>
                    <AppProviders>
                        <ErrorBoundary fallback={<Text text='general.unable-to-load-view' />}>
                            <Suspense fallback={<ViewLoading />}>
                                <Routes>
                                    <Route path='/suppliers/*' element={<SuppliersRoutes />}></Route>
                                </Routes>
                                <SuppliersRoutes />
                            </Suspense>
                        </ErrorBoundary>
                    </AppProviders>
                </SqueegeeBalance>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );

    return {
        unmount: () => root.unmount(),
    };
};
