import { Outlet, Route, Routes } from 'react-router';

import { Box, Container, Typography } from '@mui/material';
import {
    AccountTransactionsView,
    BalanceRefreshBanner,
    BalanceSettingsView,
    ChartOfAccountsView,
    DashboardView,
    FundsAccountView,
    ReportView,
    ReportsOverview,
    Submissions,
    TestHeaders,
    VATReturnObligations,
    VATSetupView,
} from '@nexdynamic/balance-components-react';
import { Suspense } from 'react';
import ViewLoading from '../components/ViewLoading';

const View = () => {
    return (
        <>
            <BalanceRefreshBanner bannerPosition="bottom" pollInterval={1000} />

            <Outlet />
        </>
    );
};

const BalanceRoutes = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path="/" element={<View />}>
                    <Route
                        path="/dashboard/*"
                        element={
                            <Box sx={{ pb: 8 }}>
                                <DashboardView />
                            </Box>
                        }
                    />
                    <Route path="/accounts/*" element={<ChartOfAccountsView />}></Route>
                    <Route path="/fund-accounts/*" element={<FundsAccountView />}></Route>
                    <Route path="/fund-accounts/:id" element={<FundsAccountView />}></Route>
                    <Route path="/reports/*" element={<ReportsOverview />} />
                    <Route path="/reports/:type" element={<ReportView />} />
                    <Route path="/submissions/*" element={<Submissions />} />
                    <Route path="/vat-returns" element={<VATReturnObligations />} />
                    <Route path="/vat-returns/obligations" element={<VATReturnObligations />} />
                    <Route path="/vat-returns/setup" element={<VATSetupView />} />
                    <Route path="/transactions/*" element={<AccountTransactionsView />}></Route>
                    <Route path="/settings/*" element={<BalanceSettingsView hideProviders={true} />} />
                    <Route
                        path="/test-headers/*"
                        element={
                            <Container maxWidth="xl">
                                <TestHeaders />
                            </Container>
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <Typography variant="h6" textAlign="center">
                                Error Page Not Found
                            </Typography>
                        }
                    />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default BalanceRoutes;
