import type { Job, StoredObject } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { Data } from '../../Data/Data';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';

type Options = {
    watch?: boolean;
};
const useStoredObject = <TObjectType extends Exclude<StoredObject, Job>>(id: string | undefined, options: Options = { watch: true }) => {
    // Copy the document so the readonly isn't changed
    const [document, setDoc] = useState<TObjectType | null>(() => {
        const doc = id ? Data.get(id) : null;
        return (doc ? { ...doc } : null) as TObjectType;
    });

    // Listen for changes to this document and update the state
    useEffect(() => {
        let subscription: Subscription | undefined;
        if (options.watch) {
            subscription = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
                if (id) {
                    const doc = event.updatedObjects[id];
                    if (typeof doc !== undefined) {
                        const doc = Data.get(id) as TObjectType;
                        // Copy the document so the readonly isn't changed
                        setDoc({ ...doc });
                    }
                } else {
                    setDoc(null);
                }
            });
        }
        return () => subscription && subscription.dispose();
    }, [id, options]);

    return document;
};

export default useStoredObject;
