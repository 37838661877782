import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import { backupServer } from '../../../../Data/backupServer';
import { Api } from '../../../../Server/Api';
import useStateFlags from '../../../hooks/useStateFlags';

type Backup = {
    id: string;
    date: string;
    size: number;
};

type BackupWeekInfo = {
    startDate: string;
    backups: Array<Backup>;
};

type BackupTree = Record<string, Record<string, Record<string, BackupWeekInfo>>>;

export const useBackups = (): { mostRecentBackup?: Backup; backupTree?: BackupTree; refresh: () => void } => {
    const [mostRecentBackup, setMostRecentBackup] = useState<Backup>();
    const [backupTree, setBackupTree] = useState<BackupTree>();
    const [refreshKey, setRefreshKey] = useState(0);

    const isUltimate = ApplicationState.hasUltimateOrAbove;
    const { devMode } = useStateFlags();

    useEffect(() => {
        Api.get<Array<Backup>>(backupServer(), '/api/backups').then(backupsResponse => {
            const backups = backupsResponse?.data.filter(backup => isUltimate || devMode || backup.id.includes('backup-manual-'));
            if (!backups) return;

            // Set the latest backup as the most recent
            setMostRecentBackup(backups.sort((a, b) => b.date.localeCompare(a.date))[0]);

            const backupTree: BackupTree = {};
            for (const backup of backups) {
                const date = new Date(backup.date);
                const year = date.getFullYear().toString();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const weekNumber = Math.ceil(date.getDate() / 7);
                const week = `Week${weekNumber}`;
                const startDate = new Date(date.setDate(date.getDate() - date.getDay() + 1)).toISOString().split('T')[0];

                if (!backupTree[year]) {
                    backupTree[year] = {};
                }
                if (!backupTree[year][month]) {
                    backupTree[year][month] = {};
                }
                if (!backupTree[year][month][week]) {
                    backupTree[year][month][week] = { startDate, backups: [] };
                }
                backupTree[year][month][week].backups.push(backup);
            }

            // Sort the months based on the actual order not alphabetically
            for (const year in backupTree) {
                const sortedMonths = Object.keys(backupTree[year]).sort((a, b) => parseInt(a) - parseInt(b));
                const sortedMonthsObject: Record<string, Record<string, BackupWeekInfo>> = {};
                for (const month of sortedMonths) {
                    sortedMonthsObject[month] = backupTree[year][month];
                }
                backupTree[year] = sortedMonthsObject;
            }

            setBackupTree(backupTree);
        });
    }, [refreshKey]);

    return {
        mostRecentBackup,
        backupTree,
        refresh: () => setRefreshKey(refreshKey + 1),
    };
};
