import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, ToggleButton } from '@mui/material';
import { AuthStates } from '@nexdynamic/squeegee-portal-common';
import { useMemo } from 'react';
import { usePortalLayout } from '../../PortalLayoutProvider';
import { widgetSizes } from '../../utils/widgetSizes';
import { parseComponentName } from '../WidgetSkeleton';

const WidgetSelector = () => {
    const { layout, preferredAuthMode: authState, handleInsertComponent } = usePortalLayout();

    const componentNames = useMemo(() => Object.keys(widgetSizes), []).filter(
        name => name !== 'layout' && name !== 'spacer' && name !== 'address-search' && name !== 'quote-flow'
    ) as Array<keyof typeof widgetSizes>;

    return (
        <Grid container spacing={1} columns={6}>
            {componentNames.map((componentName, index) => {
                const thisAuthState = AuthStates[componentName];
                const disabled = thisAuthState !== authState && thisAuthState !== 'optional';

                return (
                    <Grid item key={index} xs={3} sm={2} md={1} lg={1}>
                        <ToggleButton
                            value={componentName}
                            size="small"
                            key={index}
                            onClick={() => handleInsertComponent(componentName)}
                            selected={layout.some(l => l.i === componentName)}
                            sx={{ height: '100%', textTransform: 'none', fontSize: '1rem' }}
                            fullWidth
                            disabled={disabled}
                        >
                            {parseComponentName(componentName)}
                        </ToggleButton>
                    </Grid>
                );
            })}
            <Grid item xs={3} sm={2} md={1} lg={1}>
                <Button
                    startIcon={<AddIcon />}
                    variant="outlined"
                    size="small"
                    onClick={() => handleInsertComponent('spacer')}
                    sx={{ height: '100%', py: 1 }}
                    fullWidth
                >
                    Add Spacer
                </Button>
            </Grid>
        </Grid>
    );
};

export default WidgetSelector;
