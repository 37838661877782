import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import type { NexAction } from '@nexdynamic/nex-ui-react';
import type { Template } from '@nexdynamic/squeegee-common';
import * as React from 'react';
import useTemplateActions from '../hooks/useTemplateActions';

export type TemplateAction = {
    label: string;
    show?: (template: Template) => boolean;
    onClick: (template: Template) => void;
};

type Props = {
    template: Template;
};

export const TemplateActions = ({ template }: Props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { edit, remove } = useTemplateActions(template._id);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const primaryAction = template.readonly ? null : edit;
    const secondaryActions: Array<NexAction<() => void>> = [];

    if (template.readonly !== true) secondaryActions.push(remove);

    const handleAction = (action?: NexAction<() => void>) => {
        handleClose();
        if (action) action.run();
    };

    return (
        <React.Fragment>
            {secondaryActions.length === 0 ? (
                <>
                    {primaryAction && (
                        <Button ref={anchorRef} variant='outlined' onClick={() => handleAction(primaryAction)} size='small'>
                            {primaryAction.name}
                        </Button>
                    )}
                </>
            ) : (
                <>
                    {primaryAction && (
                        <ButtonGroup ref={anchorRef} variant='outlined' aria-label='Template Actions'>
                            <Button variant='outlined' onClick={() => handleAction(primaryAction)} size='small'>
                                {primaryAction.name}
                            </Button>
                            <Button size='small' aria-haspopup='menu' onClick={handleToggle}>
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                    )}
                </>
            )}
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper elevation={2}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {secondaryActions.map((action, index) => (
                                        <MenuItem key={action.name + index} onClick={() => handleAction(action)}>
                                            <Typography>{action.name}</Typography>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default TemplateActions;
