import { calculatePercentageChange } from '@nexdynamic/nex-ui-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSetting } from '../../hooks/useSetting';
import { determineGrouping, groupTimestamps } from './portalAnalyticsUtils';

export type PortalAnalyticsViews = Array<number>;
export type Group = { time: string; count: number; percentageChange?: number };
export type Grouping = 'hour' | 'day' | 'week' | 'month';

const usePortalViews = (startDate: moment.Moment, endDate: moment.Moment) => {
    const [grouping, setGrouping] = useState<Grouping>('hour');
    const [groupedViews, setGroupedViews] = useState<Group[]>([]);
    const [viewPercentageChange, setViewPercentageChange] = useState<number>(0);

    const [portalViews] = useSetting('global.portal-views', new Array<number>());

    useEffect(() => {
        const groupingType = determineGrouping(startDate, endDate);
        setGrouping(groupingType);

        const filteredViews = portalViews.filter(timestamp => {
            const date = moment(timestamp);
            return date.isBetween(startDate, endDate, undefined, '[]');
        });

        const currentGroupedViews = groupTimestamps(groupingType, filteredViews, {
            start: startDate.toDate(),
            end: endDate.toDate(),
        });

        const previousPeriodStart = startDate.clone().subtract(endDate.diff(startDate));
        const previousViews = portalViews.filter(timestamp => {
            const date = moment(timestamp);
            return date.isBetween(previousPeriodStart, startDate, undefined, '[]');
        });

        const previousGroupedViews = groupTimestamps(groupingType, previousViews, {
            start: previousPeriodStart.toDate(),
            end: startDate.toDate(),
        });

        const totalPreviousViews = previousGroupedViews.reduce((acc, cur) => acc + cur.count, 0);
        const totalCurrentViews = currentGroupedViews.reduce((acc, cur) => acc + cur.count, 0);

        setViewPercentageChange(calculatePercentageChange(totalPreviousViews, totalCurrentViews));

        setGroupedViews(currentGroupedViews.map(g => ({ ...g, percentageChange: viewPercentageChange })));
    }, [portalViews, startDate, endDate]);

    return {
        groupedViews,
        grouping,
        viewPercentageChange,
    };
};

export default usePortalViews;
