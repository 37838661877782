import type {
    AccountUser,
    Customer,
    IScheduleItem,
    Job,
    JobOccurrence,
    JobOrderData,
    LngLat,
    Signature,
    StoredEvent,
    Team,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    AlphanumericCharColourDictionary,
    FrequencyBuilder,
    FrequencyType,
    JobOccurrenceStatus,
    getImportantHashtags,
    getSummedTimestampOfEntryDurations,
    isTimerRunningForEntries,
    sortByCreatedDateAsc,
} from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationEnvironment } from '../../ApplicationEnvironment';
import { ApplicationState } from '../../ApplicationState';
import { DateFromNowValueConverter } from '../../Converters/DateFromNowValueConverter';
import { Data } from '../../Data/Data';
import type { IIndicator } from '../../Jobs/IIndicator';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import { StoredEventService } from '../../Tracking/StoredEventService';
import { TransactionService } from '../../Transactions/TransactionService';
import { UISelectable } from '../../UISelectable';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { Utilities } from '../../Utilities';
import { ScheduleItemActions } from '../Actions/ScheduleItemActions';
import { ScheduleService } from '../ScheduleService';

export const ScheduleItemStatusColour: { [index: string]: string } = {
    'not-due': '#486493',
    'completed': '#66B988',
    'skipped': '#F8C007',
    'due': '#486493',
    'overdue': '#F66578',
    'invoiced': '#e67635',
    'paid': '#AB47BC',
    'done': '#66B988',
    'future-appointment': '#486493',
    'not-scheduled': '#486493',
};

export type ScheduleItemStatus =
    | 'not-scheduled'
    | 'not-due'
    | 'completed'
    | 'skipped'
    | 'due'
    | 'overdue'
    | 'invoiced'
    | 'paid'
    | 'done'
    | 'future-appointment';
export class ScheduleItem extends UISelectable implements IScheduleItem {
    public updateOccurrence(occurrence: JobOccurrence) {
        this._occurrence = occurrence;
    }
    public static _colours = new AlphanumericCharColourDictionary();
    public representingJob = false;

    protected applicationState = ApplicationState;
    @computedFrom('_customer')
    public get customer() {
        return this._customer;
    }
    @computedFrom('_job')
    public get job() {
        return this._job;
    }

    @computedFrom('_occurrence')
    public get occurrence() {
        return this._occurrence;
    }

    @computedFrom('_nextScheduleItem')
    get timeToNextApp(): string {
        return (this.nextScheduleItem?.date && moment(this.nextScheduleItem?.date).from(this.date, true)) || '';
    }

    @computedFrom('_previousScheduleItem')
    get timeSinceLastApp(): string {
        return (this.previousScheduleItem?.date && moment(this.previousScheduleItem?.date).from(this.date, true)) || '';
    }

    constructor(private _job: Job, private _customer: Customer, private _occurrence: JobOccurrence) {
        super();

        if (!_occurrence.customerId) {
            // Upgrade occurrence code
            _occurrence.customerId = _customer._id;
            _occurrence.description = _job.description;
            _occurrence.location = _job.location;
            _occurrence.price = _job.price || 0;
            _occurrence.services = _job.services.slice(0);
            _occurrence.time = _job.time;
        }
    }

    public displayIndex: number;

    @computedFrom('ocurrence.duration')
    public get duration() {
        return this.occurrence.duration || ApplicationState.account.defaultJobDuration || '';
    }

    private _plannedOrder?: number;

    @computedFrom('_plannedOrder')
    public get plannedOrder(): number {
        if (this._plannedOrder === undefined) this.refreshPlannedOrder();
        return this._plannedOrder === undefined ? this.displayIndex || 0 : this._plannedOrder;
    }

    @computedFrom('duration')
    public get durationInSeconds(): number {
        const time = this.duration.split(':');
        const hh = parseFloat(time[0]);
        const mm = parseFloat(time[1]);

        return Number(hh) * 60 * 60 + Number(mm) * 60;
    }

    @computedFrom('job')
    public get roundColour(): string {
        return (
            this.job.rounds?.[0]?.color ||
            ScheduleItem._colours[this.job.rounds?.[0]?.description?.slice(0, 1).toUpperCase()] ||
            ScheduleItem._colours['X']
        );
    }

    private _scheduleItemActions?: ScheduleItemActions;
    @computedFrom('_scheduleItemActions')
    public get scheduleItemActions(): ScheduleItemActions {
        if (!this._scheduleItemActions) {
            this._scheduleItemActions = new ScheduleItemActions(this);
        }
        return this._scheduleItemActions;
    }

    private _avatar?: string;
    @computedFrom('displayIndex', '_avatar', 'customer.name')
    public get avatar() {
        if (this._avatar === undefined) {
            this._avatar = this.plannedOrder
                ? this.plannedOrder.toString()
                : this.job.location?.addressDescription?.substring(0, 2).toUpperCase() || '';
            delete this._avatarColour;
        }

        return this._avatar;
    }

    private _avatarColour?: string;
    @computedFrom('_avatar', '_avatarColour', 'occurrence.location.isVerified')
    public get avatarColour() {
        if (this._avatarColour === undefined) {
            if (this.job.rounds && this.job.rounds.length > 0 && this.job.rounds[0].color) {
                this._avatarColour = this.job.rounds[0].color;
            } else {
                const roundName = this.roundName;
                this._avatarColour = roundName
                    ? ScheduleItem._colours[roundName.substring(0, 1).toUpperCase()]
                    : this.avatar !== undefined
                    ? ScheduleItem._colours[this.avatar.substring(0, 1).toUpperCase()]
                    : '#fff';
            }
        }

        return this._avatarColour;
    }

    private _isFirst?: boolean;
    @computedFrom('_isFirst')
    public get isFirst() {
        if (this._isFirst === undefined) {
            this._isFirst = false;
            setTimeout(() => {
                const hasExisting = !!Data.firstOrDefault<JobOccurrence>('joboccurrences', { jobId: this.job._id });
                if (!hasExisting && this.occurrence.isoDueDate === this.job.date) {
                    this._isFirst = true;
                } else if (
                    hasExisting &&
                    !Data.firstOrDefault<JobOccurrence>('joboccurrences', x => x.isoDueDate < this.occurrence.isoDueDate, {
                        jobId: this.job._id,
                    })
                ) {
                    this._isFirst = true;
                }
            });
        }
        return this._isFirst;
    }

    private _assignees?: Array<AccountUser | Team> | null;
    @computedFrom('_assignees')
    public get isAssigned() {
        return this._assignees && this._assignees.length > 0;
    }

    @computedFrom('_assignees')
    public get assignees(): Array<AccountUser | Team> | null {
        if (this._assignees === undefined) {
            if (!ApplicationState.multiUserEnabled) return [];
            this._assignees = null;
            this._assignees = AssignmentService.getAssignees(this.occurrence);
        }
        return this._assignees;
    }

    @computedFrom('_assignees')
    public get assignedTo() {
        return (this.assignees && this.assignees.map(x => x.name).join(', ')) || ApplicationState.localise('general.unassigned');
    }

    private _signature?: Signature | null;
    @computedFrom('_signature')
    public get signature() {
        if (this._signature === undefined) {
            this._signature = null;
            if (this.occurrence.signatureId) {
                this._signature = Data.get<Signature>(<string>this.occurrence.signatureId) || null;
            }
        }
        return this._signature;
    }

    private _frequencyText?: string;
    @computedFrom('_frequenctText')
    public get frequencyText() {
        if (this._frequencyText === undefined) {
            if (!this.job.date) {
                if (this.job.externalCalendarUrl && this.job.externalCalendarUrl.length > 0) {
                    this._frequencyText = ApplicationState.localise('jobs.external-calendar-schedule');
                } else {
                    this._frequencyText = 'not scheduled';
                }
            } else {
                const frequency = FrequencyBuilder.getFrequency(
                    this.job.frequencyInterval,
                    this.job.frequencyType,
                    this.job.date,
                    this.job.frequencyDayOfMonth,
                    this.job.frequencyWeekOfMonth,
                    Array.isArray(this.job.frequencyDayOfWeek) ? this.job.frequencyDayOfWeek[0] : this.job.frequencyDayOfWeek
                );
                this._frequencyText = ApplicationState.localise(
                    frequency.localisationKeyAndParams.key,
                    frequency.localisationKeyAndParams.params
                );
            }
        }
        return this._frequencyText;
    }

    @computedFrom('occurrence.location.isVerified')
    public get isVerifiedLocation() {
        return this.occurrence.location?.isVerified ? true : false;
    }

    private _statusColour?: string;
    @computedFrom('_statusColour')
    public get statusColour() {
        if (this.scheduleStatus && this._statusColour === undefined) {
            this._statusColour = ScheduleItemStatusColour[this.scheduleStatus];
        }
        return this._statusColour;
    }

    private _distance?: number;
    @computedFrom('_distance')
    public get distance() {
        return this._distance;
    }

    private _distanceFromMe?: number;
    @computedFrom('_distanceFromMe', 'applicationState.location')
    public get distanceFromMe() {
        if (this._distanceFromMe === undefined) {
            this._distanceFromMe = 0;
            if (this.occurrence.location?.isVerified && this.occurrence.location.lngLat && this.occurrence.location.lngLat.length === 2) {
                this._distanceFromMe = LocationUtilities.getDistance(this.occurrence.location.lngLat) || 0;
            }
        }
        return this._distanceFromMe;
    }

    @computedFrom('_distanceFromMe')
    public get distanceFromMeText() {
        if (this._distanceFromMe === undefined) {
            this._distanceFromMe = 0;
            if (this.occurrence.location?.isVerified && this.occurrence.location.lngLat && this.occurrence.location.lngLat.length === 2) {
                this._distanceFromMe = LocationUtilities.getDistance(this.occurrence.location.lngLat) || 0;
            }
        }

        const distance = this._distanceFromMe > 99 ? this._distanceFromMe.toFixed(0) : this._distanceFromMe.toFixed(1);

        return distance.toString();
    }

    private _price?: number;
    private _priceSet: boolean;
    @computedFrom('_price')
    public get price() {
        if (!this._priceSet) {
            this._priceSet = true;
            if (
                this.isOwnerAdminOrCreator ||
                this.customer.hideJobPricesFromWorker === false ||
                ((this.customer.hideJobPricesFromWorker === undefined || this.customer.hideJobPricesFromWorker === null) &&
                    ApplicationState.account.hidePricesFromWorkerRole !== true)
            ) {
                this._price = this.occurrence.price || 0;
            }
        }
        return this._price;
    }

    private _formattedPrice?: string;
    @computedFrom('price', '_formattedPrice')
    public get formattedPrice() {
        if (this._formattedPrice === undefined && this.price)
            if (
                this.isOwnerAdminOrCreator ||
                this.customer.hideJobPricesFromWorker === false ||
                (this.customer.hideJobPricesFromWorker === undefined && !ApplicationState.account.hidePricesFromWorkerRole)
            ) {
                this._formattedPrice = `${ApplicationState.currencySymbol()}${(this.occurrence.price || 0).toFixed(2)}`;
            }
        return this._formattedPrice;
    }

    private _isOwnerAdminOrCreator: boolean | undefined;
    @computedFrom('_isOwnerAdminOrCreator')
    public get isOwnerAdminOrCreator() {
        if (this._isOwnerAdminOrCreator === undefined) {
            this._isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
        }
        return this._isOwnerAdminOrCreator;
    }

    private _driveOptimisationRank?: number;
    @computedFrom('_driveOptimisationRank')
    public get driveOptimisationRank() {
        return this._driveOptimisationRank || -1;
    }
    public set driveOptimisationRank(newValue: number) {
        this._driveOptimisationRank = newValue;
    }

    @computedFrom('job.rounds.length')
    public get roundName() {
        return this.job.rounds && this.job.rounds.length
            ? Utilities.localiseList(this.job.rounds.map(r => r.description as TranslationKey))
            : '';
    }

    @computedFrom('job.rounds[0].id')
    public get roundId() {
        if (this.job.rounds && this.job.rounds.length) {
            return this.job.rounds[0]._id;
        }
    }

    private _dateFromNow?: string;
    @computedFrom('_dateFromNow')
    public get dateFromNow() {
        if (!this._dateFromNow) {
            this._dateFromNow = DateFromNowValueConverter.getFromNow(moment(this.date));
        }
        return this._dateFromNow;
    }

    private _formattedDate?: string;
    @computedFrom('_formattedDate')
    public get formattedDate() {
        if (!this._formattedDate) {
            const date = moment(this.date);
            this._formattedDate = date.isBefore(moment(), 'year') ? date.format('ddd, MMM Do YYYY') : date.format('ddd, MMM Do');
        }
        return this._formattedDate;
    }

    private _indicators?: Array<IIndicator>;
    @computedFrom('_indicators', 'occurrence', 'distance', 'assignee')
    public get indicators() {
        if (!this._indicators) {
            this._indicators = JobOccurrenceService.getOccurrenceIndicators(this.occurrence, undefined, undefined) || [];
        }
        return this._indicators;
    }

    private _isAutoInvoiced?: boolean;
    @computedFrom('_isAutoInvoiced')
    public get isAutoInvoiced() {
        if (this._isAutoInvoiced === undefined) {
            if (this.customer.autoInvoiceMethod === 'manual') {
                this._isAutoInvoiced = false;
            } else if (this.customer.autoInvoiceMethod === 'auto') {
                this._isAutoInvoiced = true;
            } else if (
                ApplicationState.account.invoiceSettings &&
                ApplicationState.account.invoiceSettings.defaultInvoiceGenerationMethod === 'manual'
            ) {
                this._isAutoInvoiced = false;
            }
            this._isAutoInvoiced = true;
        }
        return this._isAutoInvoiced;
    }

    @computedFrom('customer.automaticPaymentStatus')
    public get isAutoPaid() {
        return (
            !!this.customer.automaticPaymentMethod &&
            (this.customer.paymentProviderMetaData?.gocardless?.status === 'active' ||
                this.customer.paymentProviderMetaData?.stripe?.status === 'active')
        );
    }

    private _isOwing?: 'owing' | 'credit' | '';
    @computedFrom('_isOwing')
    public get isOwing() {
        if (this._isOwing === undefined && this._balance) {
            this._isOwing = this._balance > 0 ? 'owing' : this._balance < 0 ? 'credit' : '';
        }
        return this._isOwing;
    }

    private _balance?: number;
    @computedFrom('_balance')
    public get balance() {
        if (this._balance === undefined) {
            this._balance = TransactionService.getCustomerBalance(this.customer._id, ApplicationEnvironment.today).amount;
        }
        return Math.abs(this._balance);
    }

    private _trueBalance?: number;
    @computedFrom('_trueBalance')
    public get trueBalance() {
        if (this._trueBalance === undefined) {
            this._trueBalance = TransactionService.getCustomerBalance(this.customer._id, ApplicationEnvironment.today).amount;
        }
        return this._trueBalance;
    }

    @computedFrom('occurrence.status')
    public get status(): JobOccurrenceStatus {
        return this.occurrence.status;
    }

    private _status?: ScheduleItemStatus;

    @computedFrom('_status')
    public get scheduleStatus(): ScheduleItemStatus | undefined {
        // Do we have invoicing?
        this.initialiseStatus();
        return this._status;
    }

    public statusLabel: TranslationKey;

    private initialiseStatus() {
        if (this._status === undefined) {
            switch (this.occurrence.status) {
                case JobOccurrenceStatus.NotScheduled: {
                    this._status = 'not-scheduled';
                    this.statusLabel = 'Not Scheduled' as TranslationKey;
                    break;
                }
                case JobOccurrenceStatus.NotDone: {
                    const today = moment().format('YYYY-MM-DD');
                    const dateValue = this.occurrence.isoPlannedDate || this.occurrence.isoDueDate;
                    if (dateValue < today) {
                        this.statusLabel = 'general.status-overdue';
                        this._status = 'overdue';
                    } else if (dateValue.substring(0, 10) === today) {
                        if (this.occurrence.paymentStatus === 'invoiced') this.statusLabel = 'general.status-due-invoiced';
                        else if (this.occurrence.paymentStatus === 'paid') this.statusLabel = 'general.status-due-paid';
                        else this.statusLabel = 'general.status-due';
                        this._status = 'due';
                    } else {
                        if (this.occurrence.paymentStatus === 'invoiced') this.statusLabel = 'general.status-future-appointment-invoiced';
                        else if (this.occurrence.paymentStatus === 'paid') this.statusLabel = 'general.status-future-appointment-paid';
                        else this.statusLabel = 'general.status-future-appointment';

                        this._status = 'future-appointment';
                    }
                    break;
                }
                case JobOccurrenceStatus.Done:
                    switch (this.occurrence.paymentStatus) {
                        case 'invoiced':
                            this.statusLabel = 'general.status-invoiced';
                            this._status = 'invoiced';
                            break;
                        case 'paid':
                            this.statusLabel = 'general.status-paid';
                            this._status = 'paid';
                            break;
                        default:
                            this.statusLabel = 'general.done';
                            this._status = 'done';
                            break;
                    }

                    break;
                case JobOccurrenceStatus.Skipped:
                    this.statusLabel = 'general.status-skipped';
                    this._status = 'skipped';
                    break;
            }
        }
    }

    @computedFrom('occurrence.status', 'occurrence.isoPlannedDate', 'occurrence.isoDueDate', 'occurrence.isoCompletedDate')
    public get date(): string {
        let plannedDate: string;
        if (this.occurrence.status === JobOccurrenceStatus.NotDone) {
            plannedDate = this.occurrence.isoPlannedDate || this.occurrence.isoDueDate;
        } else {
            plannedDate = this.occurrence.isoCompletedDate || this.occurrence.isoPlannedDate || this.occurrence.isoDueDate;
        }
        return plannedDate;
    }

    private _distanceText?: string;
    @computedFrom('_distanceText')
    public get distanceText() {
        return this._distanceText;
    }

    public refreshPlannedOrder() {
        const ignoreReplanned = this.representingJob;
        const jobOrderDatas = Data.all<JobOrderData>('joborderdata', x => x.type === 'global-day')
            .slice()
            .sort(sortByCreatedDateAsc);

        if (!jobOrderDatas.length) {
            this._plannedOrder = this.job.plannedOrder;
            return;
        }
        const orderData = jobOrderDatas[0];
        const occOrder = orderData.data.indexOf(this.occurrence._id);
        const jobOrder = orderData.data.indexOf(this.job._id);
        const order =
            occOrder > -1 &&
            !ignoreReplanned &&
            this.occurrence.isoPlannedDate &&
            this.occurrence.isoPlannedDate !== this.occurrence.isoDueDate
                ? occOrder
                : jobOrder;

        this._plannedOrder = order;
    }
    public refreshDistance(from?: LngLat) {
        if (!from) {
            this._distanceText = undefined;
            this._avatar = undefined;
            return;
        }

        this._distanceText = '';
        if (this.occurrence.location && this.occurrence.location.lngLat) {
            this._distance = 1.25 * (LocationUtilities.getDistance(this.occurrence.location.lngLat, from) || 0);
            if (this._distance === 0) return;

            if (this._distance > 99) this._distanceText = `>99`;
            else if (this._distance > 10) this._distanceText = `${this._distance.toFixed(0)}`;
            else this._distanceText = `${this._distance.toFixed(1)}`;
        } else {
            this._distance = 0;
        }
    }

    public refreshDistanceFromMe() {
        this._distanceFromMe = undefined;
    }

    public static refresh(scheduleItem: ScheduleItem) {
        try {
            if (!scheduleItem) return void Logger.info('ScheduleItem.refresh called with no scheduleItem');

            scheduleItem._customer = Data.get<Customer>(scheduleItem.customer._id) as Customer;
            scheduleItem._job = scheduleItem.customer.jobs[scheduleItem.job._id];

            const occurrence = Data.get<JobOccurrence>(scheduleItem.occurrence._id);
            if (occurrence) scheduleItem._occurrence = occurrence;
            scheduleItem._dateFromNow = undefined;
            scheduleItem._serviceList = undefined;
            scheduleItem._formattedDate = undefined;
            scheduleItem._indicators = undefined;
            scheduleItem._avatar = undefined;
            scheduleItem._avatarColour = undefined;
            scheduleItem._status = undefined;
            scheduleItem._statusColour = undefined;
            scheduleItem._assignees = undefined;
            scheduleItem._price = undefined;
            scheduleItem._priceSet = false;
            scheduleItem._plannedOrder = undefined;
            scheduleItem._isFirst = undefined;
            scheduleItem._balance = undefined;
            scheduleItem._trueBalance = undefined;
            scheduleItem._isOwing = undefined;
            scheduleItem._state = undefined;
            scheduleItem._isOwnerAdminOrCreator = undefined;
            scheduleItem._assignees = undefined;
            scheduleItem._tags = undefined;
            scheduleItem._distanceFromMe = undefined;
            scheduleItem._canBeDeleted = undefined;
            scheduleItem._isOnSchedule = undefined;
            scheduleItem._previousScheduleItem = undefined;
            scheduleItem._nextScheduleItem = undefined;
            scheduleItem._icon = undefined;
            scheduleItem._iconTitle = undefined;
            scheduleItem._isAutoInvoiced = undefined;
            scheduleItem._events = undefined;
            scheduleItem.scheduleItemActions.updateActions();
            scheduleItem.refreshMissingSkills();
        } catch (error) {
            Logger.info('Failed to refresh schedule item.', { error, scheduleItem });
        }
    }

    private _serviceList: string | undefined;
    @computedFrom('_serviceList')
    public get serviceList() {
        if (!this._serviceList) {
            this._serviceList = Utilities.localiseList(
                this.occurrence.services.map(s => {
                    const quantity = s.quantity && s.quantity > 1 ? ` x ${s.quantity}` : '';
                    return `${s.description}${quantity}` as TranslationKey;
                })
            );
        }
        return this._serviceList;
    }

    private _lastPaymentMethod?: string | null;
    @computedFrom('_lastPaymentMethod')
    public get lastPaymentMethod() {
        if (this._lastPaymentMethod === undefined) {
            this._lastPaymentMethod = this.customer.lastPaymentMethod || null;
        }
        return this._lastPaymentMethod;
    }

    @computedFrom('customer.name')
    public get customerName() {
        return this.customer.name;
    }

    @computedFrom('occurrence.location.addressDescription')
    public get addressDescription() {
        return this.occurrence.location?.addressDescription;
    }
    @computedFrom('occurrence.location.addressDescription')
    public get addressDescriptionPrimary() {
        return this.occurrence.location?.addressDescription?.substring(0, this.occurrence.location?.addressDescription.indexOf(','));
    }
    @computedFrom('occurrence.location.addressDescription')
    public get addressDescriptionSecondary() {
        return this.occurrence.location?.addressDescription
            ?.substring(this.occurrence.location?.addressDescription.indexOf(',') + 1)
            .trim();
    }
    private _state?: string;
    public get state() {
        if (this._state === undefined) {
            if ((this.customer && this.customer.state === 'inactive') || (this.job && this.job.state === 'inactive')) {
                this._state = 'inactive';
            } else if (this.customer && this.customer.state === 'prospect') {
                this._state = 'prospect';
            } else {
                this._state = '';
            }
        }
        return this._state;
    }

    private _tags?: string[];
    @computedFrom('_tags')
    public get tags() {
        if (!this._tags) {
            const description = `${this.occurrence.description} ${
                this.job.description !== this.occurrence.description ? this.job.description : ''
            } ${this.customer.notes}`;
            this._tags = getImportantHashtags(description);
        }
        return this._tags;
    }

    private _customerNotes?: string;
    @computedFrom('_customerNotes')
    public get customerNotes() {
        if (this._customerNotes === undefined) {
            if (
                !ApplicationState.isInAnyRole(['Admin', 'Creator', 'Owner']) &&
                ApplicationState.account.hideCustomerNotesFromWorkersAndPlanners
            )
                this._customerNotes = '';
            else this._customerNotes = this.customer.notes || '';
        }
        return this._customerNotes;
    }

    private _canBeDeleted: boolean | undefined;
    @computedFrom('_canBeDeleted')
    public get canBeDeleted() {
        if (this._canBeDeleted === undefined) {
            if (this.occurrence.status === JobOccurrenceStatus.Done || this.occurrence.status === JobOccurrenceStatus.Skipped)
                return (this._canBeDeleted = false);

            if (!this.job.date) return (this._canBeDeleted = true);

            if (this.job.frequencyType === FrequencyType.NoneRecurring) return (this._canBeDeleted = true);

            if (this.job.date > this.occurrence.isoDueDate) return (this._canBeDeleted = true);

            if (!this.isOnSchedule) return (this._canBeDeleted = true);

            return (this._canBeDeleted = false);
        }

        return this._canBeDeleted;
    }

    private _isOnSchedule?: boolean;
    @computedFrom('_isOnSchedule')
    public get isOnSchedule() {
        if (this._isOnSchedule === undefined) {
            const next = this.nextScheduleItem;
            if (!next) return (this._isOnSchedule = false);

            const possiblyThis = next.previousScheduleItem;
            if (!possiblyThis) return (this._isOnSchedule = false);

            if (possiblyThis.occurrence.isoDueDate === this.occurrence.isoDueDate) return (this._isOnSchedule = true);

            this._isOnSchedule = false;
        }

        return this._isOnSchedule;
    }

    private _previousScheduleItem?: ScheduleItem | null;
    @computedFrom('_previousScheduleItem')
    public get previousScheduleItem(): ScheduleItem | null {
        if (this._previousScheduleItem === undefined) {
            return (this._previousScheduleItem = ScheduleService.getPreviousScheduleItemForJob(this.job, this.date) || null);
        }
        return this._previousScheduleItem;
    }

    private _nextScheduleItem?: ScheduleItem | null;
    @computedFrom('_nextScheduleItem')
    public get nextScheduleItem(): ScheduleItem | null {
        if (this._nextScheduleItem === undefined) {
            return (this._nextScheduleItem =
                ScheduleService.getNextScheduleItemForJob(this.job, moment(this.date).add(1, 'day').format('YYYY-MM-DD')) || null);
        }
        return this._nextScheduleItem;
    }

    private _icon?: string;
    @computedFrom('_icon')
    public get icon() {
        if (this._icon === undefined) {
            const today = moment().format('YYYY-MM-DD');
            this._icon =
                this.status === JobOccurrenceStatus.Done
                    ? 'event_available'
                    : this.status === JobOccurrenceStatus.Skipped
                    ? 'event_busy'
                    : this.job.frequencyType === 0
                    ? 'book_online'
                    : !this.isOnSchedule
                    ? 'event_busy'
                    : this.occurrence.isoPlannedDate && this.occurrence.isoPlannedDate !== this.occurrence.isoDueDate
                    ? 'date_range'
                    : this.date === today
                    ? 'today'
                    : 'event';
        }
        return this._icon;
    }

    private _iconTitle?: string;
    @computedFrom('_iconTitle')
    public get iconTitle() {
        if (this._iconTitle === undefined) {
            this._iconTitle =
                this.status === JobOccurrenceStatus.Done
                    ? 'This appointment was completed.'
                    : this.status === JobOccurrenceStatus.Skipped
                    ? 'This appointment was skipped.'
                    : this.job.frequencyType === 0
                    ? 'This is an ad-hoc appointment.'
                    : !this.isOnSchedule
                    ? "This appointment is not part of it's schedule."
                    : !this.occurrence.isoPlannedDate || this.occurrence.isoPlannedDate === this.occurrence.isoDueDate
                    ? "This appointment is on it's original planned date."
                    : `This appointment was replanned from ${moment(this.occurrence.isoDueDate).format('ll')} to ${moment(
                          this.occurrence.isoPlannedDate
                      ).format('ll')}.`;
        }
        return this._iconTitle;
    }

    @computedFrom('customer', 'applicationState')
    public get canViewBalance() {
        return (
            this.applicationState.isInAnyRole(['Owner', 'Admin', 'Creator']) ||
            (!this.customer.hideJobPricesFromWorker && !ApplicationState.account.hidePricesFromWorkerRole)
        );
    }

    private getDiffFromInterval(date: string): { diff: number; unit: 'days' | 'weeks' | 'months' } | undefined {
        const dayGap = Math.abs(moment(this.date).diff(date, 'day'));

        if (this.job.frequencyType === 1) {
            //day
            return { diff: Math.round(this.job.frequencyInterval - dayGap), unit: 'days' };
        } else if (this.job.frequencyType === 2) {
            //week
            return { diff: Math.round(this.job.frequencyInterval - dayGap / 7), unit: 'weeks' };
        } else if (this.job.frequencyType === 3) {
            return { diff: Math.round(this.job.frequencyInterval - dayGap / 29.6875), unit: 'months' };
        }
    }

    @computedFrom('_previousScheduleItem')
    public get gapDiffToLastAppointment() {
        if (!this._previousScheduleItem?.date) return '';
        const r = this.getDiffFromInterval(this._previousScheduleItem.date);
        if (!r) return '';
        return `${r.diff} ${r.unit}`;
    }
    @computedFrom('_nextScheduleItem')
    public get gapDiffToNextAppointment() {
        if (!this._nextScheduleItem?.date) return '';
        const r = this.getDiffFromInterval(this._nextScheduleItem.date);
        if (!r) return '';
        return `${r.diff} ${r.unit}`;
    }

    private _events?: Array<StoredEvent>;

    public get events() {
        if (!this._events) {
            this._events = StoredEventService.getTimerEntriesForJobOccurrence(this.occurrence._id);
        }
        return this._events;
    }

    @computedFrom('_events')
    get actualDuration() {
        if (!this.isTimeTracked()) return false;
        if (!this.events) return false;
        if (!this.events.length) return false;
        return getSummedTimestampOfEntryDurations(this.events);
    }

    @computedFrom('_events')
    get actualDurationDescription() {
        if (!this.isTimeTracked()) return '';
        if (!this.events) return 'no entries';
        if (!this.events.length) return 'no entries';
        const duration = getSummedTimestampOfEntryDurations(this.events);
        if (!duration) return 'no entries';
        const timeInMinues = duration / 1000 / 60;
        const hours = Math.floor(timeInMinues / 60);
        const minutes = Math.floor(timeInMinues % 60);
        const totalTime = hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '');
        return totalTime;
    }

    @computedFrom('_events')
    public get timerRunning() {
        if (!this.isTimeTracked()) return false;
        if (!this.events) return false;
        if (!this.events.length) return false;

        return isTimerRunningForEntries(this.events);
    }

    @computedFrom('_events')
    public get durationText() {
        const timerEntries = this.events;
        const stopEntries = timerEntries.filter(e => e.type === 'stop');
        const startEntries = timerEntries.filter(e => e.type === 'start');
        const lastEndTime = timerEntries.length > 1 ? stopEntries[stopEntries.length - 1]?.eventTimestamp : undefined;
        const firstStartTime = timerEntries.length > 0 ? startEntries[0]?.eventTimestamp : undefined;
        return `${
            this.isTimeTracked() && firstStartTime
                ? `${moment(firstStartTime).format('hh:mm a')} - ${
                      (lastEndTime && stopEntries.length === startEntries.length && moment(lastEndTime).format('hh:mm a')) || 'now'
                  }`
                : '~' + this.duration + 'h'
        }`;
    }

    public isTimeTracked() {
        if (!ApplicationState.hasAdvancedOrAbove) return false;
        const globalTrackTime = ApplicationState.getSetting('global.jobs.track-time', false);
        const customerTrackTimeOrGlobal = ApplicationState.getSetting('customer.jobs.track-time', globalTrackTime, this.customer._id);
        const jobTrackTime = this.job.trackTime;
        return jobTrackTime !== undefined ? jobTrackTime : customerTrackTimeOrGlobal;
    }
    @computedFrom('customer.telephoneNumber', 'customer.telephoneNumberOther')
    get getPhoneContactMethod() {
        return this.customer.telephoneNumber || this.customer.telephoneNumberOther;
    }

    protected isAssigneeMissingSkills = false;
    protected totalRequiredSkills = 0;
    protected totalMissingSkills = 0;

    public refreshMissingSkills(): void {
        const requiredSkills = this.occurrence.skills;
        // If no assignees then isAssigneeMissingSkills is false
        if (!requiredSkills?.length) {
            this.isAssigneeMissingSkills = false;
            this.totalMissingSkills = 0;
            return;
        } else {
            this.totalRequiredSkills = requiredSkills.length;
        }

        if (!this.assignees?.length) {
            this.isAssigneeMissingSkills = true;
            this.totalMissingSkills = requiredSkills.length;
            return;
        }

        // If we get here we have assignees and skills, and we need to check each assignees' skills to see if they have the required skills
        const assignees: Array<AccountUser> = AssignmentService.getAssignees(this.occurrence, true) as Array<AccountUser>;
        const collectiveSkills = assignees.flatMap(a => a.skills || []);
        if (collectiveSkills.length === 0) {
            this.isAssigneeMissingSkills = true;
            this.totalMissingSkills = requiredSkills.length;
            return;
        }

        this.totalMissingSkills = requiredSkills.filter(s => !collectiveSkills.includes(s)).length;

        this.isAssigneeMissingSkills = this.totalMissingSkills > 0;
    }
}
