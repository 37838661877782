import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { createContext, useContext, useEffect, useState } from 'react';
import { ApplicationState } from '../../ApplicationState';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import { getDirectoryEntryDetails } from '../../DirectoryAndPortal/getDirectoryEntryDetails';
import { Api } from '../../Server/Api';

type DirectoryParts = {
    directory: IDirectoryEntryDetails | undefined;
    textkeys: Record<string, string | null | undefined> | undefined;
    loadingDirectory: boolean;
    saveDirectory: (directoryEntry: IDirectoryEntryDetails) => Promise<{ success: boolean; errors: Array<string> }>;
    refreshDirectory: () => void;
};

export const useDirectoryEntryDetails = (): DirectoryParts => {
    const [directory, setDirectory] = useState<IDirectoryEntryDetails | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const directory = await getDirectoryEntryDetails();
            setDirectory(directory);
            setLoading(false);
        };

        fetchData();
    }, [refresh]);

    const saveDirectory = async (directoryEntry: IDirectoryEntryDetails) => {
        if (!directory) return { success: false, errors: ['Directory not loaded'] };

        console.log('Saving directory', directoryEntry);
        const result = await Api.put<{ success: boolean; errors: Array<string> }>(
            Api.apiEndpoint,
            `/api/directory/secure/${ApplicationState.dataEmail}`,
            directoryEntry
        );
        const output = result?.data || { success: false, errors: ['No response from server'] };
        if (output.success) {
            setDirectory(directoryEntry);
        }

        if (output.errors.length > 0) {
            console.error('Error saving directory', output.errors);
            prompt('general.error', output.errors.join('\n') as TranslationKey, { cancelLabel: '' }).show();
        }

        return output;
    };

    const refreshDirectory = () => {
        setRefresh(refresh + 1);
    };

    return { directory, textkeys: directory?.config?.text, loadingDirectory: loading, saveDirectory, refreshDirectory };
};

const DirectoryEntryContext = createContext<DirectoryParts | undefined>(undefined);

export const DirectoryEntryProvider = ({ children }: { children: React.ReactNode }) => {
    const directory = useDirectoryEntryDetails();

    return <DirectoryEntryContext.Provider value={directory}>{children}</DirectoryEntryContext.Provider>;
};

export const useDirectoryEntryProvider = () => {
    const directory = useContext(DirectoryEntryContext);
    if (!directory) throw new Error('useDirectoryEntryProvider must be used within a DirectoryEntryProvider');
    return directory;
};
