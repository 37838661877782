import { BalanceDbIndexedDbStore } from '@nexdynamic/balance-components-react';
import type { BalanceAccountingMethodType, BalanceJournal, BalanceResponse } from '@nexdynamic/balance-core';
import { BalanceError, GeneralErrorCode, LedgerTypes, balanceError, balanceSuccess } from '@nexdynamic/balance-core';
import type { Transaction } from '@nexdynamic/squeegee-common';
import { BalanceTransformer } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Data } from '../../Data/Data';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { Logger } from '../../Logger';
import StoredObjectUtils from '../../utils/StoredObjectUtils';
import { generateMaps } from './generateMaps';

export class SqueegeeBalanceDbIndexedDbStore extends BalanceDbIndexedDbStore {
    static cache_version = '7'; // INCREMENT THIS IF 1. INDEXEDDB SCHEMA CHANGES OR 2. CACHE INVALIDATION IS NEEDED

    constructor(dataEmail: string) {
        super({
            dbName: `squeegee_balance_v${SqueegeeBalanceDbIndexedDbStore.cache_version}_${dataEmail}`,
        });
        this.listenForNewRecords();
    }

    async refresh(
        accountingMethod: BalanceAccountingMethodType,
        full?: boolean,
        processEntry?: ((entry: BalanceJournal, progress: number) => Promise<void>) | undefined
    ): Promise<BalanceResponse> {
        const db = await this.initialiseDb();

        const timestamp = await this.getLastSyncedTimestamp();
        const { paymentAccountToLedgerIdMap, entities, transactionLookupMap } = generateMaps(full ? undefined : timestamp || undefined);

        const tran = new BalanceTransformer(
            { salesLedgerId: 'sales', taxLedgerId: 'sales', accountingMethod },
            (id: string) => {
                return paymentAccountToLedgerIdMap.get(id) || `${LedgerTypes['assets.current_assets.funds.bank']}.general`;
            },
            (id: string) => transactionLookupMap.get(id)
        );

        let latestJournalTime: Date | undefined;
        let latestJournalId: string | undefined;

        await tran.transform(entities, async (entry: BalanceJournal, progress) => {
            try {
                await db.journals.add(entry); // Add will prevent duplicates
                if (processEntry) {
                    await processEntry(entry, progress);
                }

                if (!latestJournalTime || entry.createdAtDate > latestJournalTime) {
                    latestJournalTime = entry.createdAtDate;
                    latestJournalId = entry.journalId;
                }
            } catch (error) {
                Logger.error('Error adding journal entry to indexeddb', error);
                // we assume if an error happens if put fails due to duplicate key
            }
        });

        if (latestJournalId && latestJournalTime)
            await db.syncs.put({ timestamp: latestJournalTime.getTime(), journalId: latestJournalId });

        this._needsRefresh = false;
        SqueegeeBalanceDbIndexedDbStore._newRecordsAvailable = false;
        return balanceSuccess();
    }

    async view(uuid: string | bigint): Promise<BalanceResponse> {
        const storedObject = Data.get<Transaction>(uuid.toString());
        if (!storedObject) {
            return balanceError(new BalanceError('Transaction not found.', GeneralErrorCode.UnknownError));
        }
        const uiModel = StoredObjectUtils.getUIModelForStoredObject(storedObject);
        if (!uiModel?.showDialog) {
            return balanceError(new BalanceError('No dialog found for entity.', GeneralErrorCode.UnknownError));
        }

        await uiModel.showDialog();
        return balanceSuccess<undefined>();
    }

    static newRecordsListener: Subscription;
    async listenForNewRecords() {
        if (SqueegeeBalanceDbIndexedDbStore.newRecordsListener) return;
        SqueegeeBalanceDbIndexedDbStore.newRecordsListener = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.hasAnyType('transactions', 'supplier', 'settings', 'customers')) {
                SqueegeeBalanceDbIndexedDbStore._newRecordsAvailable = true;
            }
        });
    }

    private static _newRecordsAvailable = false;
    async newRecordsAvailable() {
        if (SqueegeeBalanceDbIndexedDbStore._newRecordsAvailable) return true;
        const timestamp = await this.getLastSyncedTimestamp();
        const sinceIso = moment(timestamp).format();
        // Do we have new transactions in squeegee since
        const count = Data.count<Transaction>('transactions', tran => tran.createdDate > sinceIso);
        SqueegeeBalanceDbIndexedDbStore._newRecordsAvailable = count > 0;
        return SqueegeeBalanceDbIndexedDbStore._newRecordsAvailable;
    }
}
