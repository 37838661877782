import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import type { IAdvertisement, TranslationKey } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import { prompt } from '../../../../Dialogs/ReactDialogProvider';
import { useSetting } from '../../../hooks/useSetting';

import {
    deleteAdvertisement,
    refreshCheckoutStatus,
    unpublishAdvertisement,
    updateAdvertisement,
} from '../../services/AdvertisementService';
import { AdvertAssets } from '../AdvertAssets';
import { AdvertTypeChip } from '../AdvertTypeLabel';
import { AttachmentCount } from '../AttachmentCount';
import { Price } from '../Price';
import { StateChip } from './MyAdvertDetails';
import PublishAdvertAlertDialog from './alerts/PublishAdvertAlert';
import TransferAssetsAlert from './alerts/TransferAssetsAlert';

type DialogProps = {
    onSaved: (advert: IAdvertisement) => void;
    onClose: () => void | undefined;
    open: boolean;
    advert: IAdvertisement;
};

type MarketplaceFees = {
    subtotal: number;
    serviceFee: number;
    transactionFee: number;
};

const MyAdvertDetails2 = ({ onSaved, onClose, open, advert }: DialogProps) => {
    const { id, tags, type, state, squeegeeAssets } = advert;

    const [publishDialogOpen, setPublishDialogOpen] = useState(false);
    const [transferDialogOpen, setTransferDialogOpen] = useState(false);
    const [updatedAdvert, setUpdatedAdvert] = useState<IAdvertisement>(advert);

    const [serviceFee] = useSetting('global.marketplace-service-fee', 0.15);
    const priceBreakdown: MarketplaceFees = {
        subtotal: Number(updatedAdvert.price),
        serviceFee: Number(updatedAdvert.price) * serviceFee,
        transactionFee: Number(updatedAdvert.price) * 0.015 + 0.2,
    };

    const handleDeleteRequest = async () => {
        if (!ApplicationState.isInAnyRole(['Admin' || 'Owner'])) return;
        const confirmationPrompt = prompt(
            'marketplace.delete-advert-confirmation-title',
            'marketplace.delete-advert-confirmation-description',
            {
                okLabel: 'general.delete',
                cancelLabel: 'general.cancel',
            }
        );

        await confirmationPrompt.show();

        if (confirmationPrompt.cancelled) return;

        await deleteAdvertisement(id);
        onSaved({ ...advert, state: 'deleted' });
        onClose();
    };

    const handleAdvertChange = (field: keyof IAdvertisement, value?: string) => {
        setUpdatedAdvert({ ...updatedAdvert, [field]: value });
    };

    const handlePublish = async () => {
        //save advert
        const fieldsToUpdate = {
            title: updatedAdvert.title,
            description: updatedAdvert.description,
            price: Number(updatedAdvert.price),
        };
        await updateAdvertisement(id, fieldsToUpdate);
        if (onSaved) onSaved({ ...advert, state: 'published' });
        onClose();
    };

    const handleUnpublish = async () => {
        await unpublishAdvertisement(id).then();
        onSaved && onSaved(advert);
        onClose();
    };

    const handleAdvertPublishToggle = async () => {
        if (state === 'published') {
            handleUnpublish();
        } else {
            setPublishDialogOpen(true);
        }
    };

    const handleAdvertSave = async () => {
        const fieldsToUpdate = { title: updatedAdvert.title, description: updatedAdvert.description, price: Number(updatedAdvert.price) };
        await updateAdvertisement(id, fieldsToUpdate);
        if (onSaved) onSaved({ ...advert, ...fieldsToUpdate });
        onClose();
    };

    const refreshCheckout = () => {
        refreshCheckoutStatus(id).then(response => {
            if (onSaved) onSaved({ ...advert, ...response });
        });
    };

    const editsDisabled = advert.state !== 'published' && advert.state !== 'draft';

    const allowedDeleteStates = ['unpublished', 'draft'];
    const deleteForbidden = !allowedDeleteStates.includes(advert.state);
    const advertState: TranslationKey = advert.isDirectTransfer ? 'marketplace.adverts-state-direct' : `advert-state.${advert.state}`;
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Advert Details</DialogTitle>
                <Stack
                    direction="row"
                    gap={0.5}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ position: 'absolute', top: 0, right: 0, p: 1.5, px: 2 }}
                >
                    <StateChip state={advertState} />
                    <IconButton onClick={handleDeleteRequest} color="error" disabled={deleteForbidden}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
                <DialogContent>
                    <Stack mb={1}>
                        <TextField
                            label="Advert Title"
                            value={updatedAdvert.title}
                            onChange={x => handleAdvertChange('title', x.target.value)}
                            fullWidth
                            disabled={editsDisabled}
                            required
                        />
                        <TextField
                            label="Advert Description"
                            value={updatedAdvert.description}
                            onChange={x => handleAdvertChange('description', x.target.value)}
                            margin="normal"
                            minRows={4}
                            multiline
                            fullWidth
                            disabled={editsDisabled}
                            required
                        />
                        <TextField
                            label="Advert Price"
                            value={updatedAdvert.price}
                            onChange={x => handleAdvertChange('price', x.target.value)}
                            InputProps={{ startAdornment: <InputAdornment position="start">{advert.currency}</InputAdornment> }}
                            inputProps={{ min: 1 }}
                            type="number"
                            margin="dense"
                            fullWidth
                            disabled={editsDisabled}
                            required
                        />
                    </Stack>
                    {squeegeeAssets && <AdvertAssets squeegeeAssets={squeegeeAssets} onClose={onClose} />}
                    <Stack direction="row" gap={1} justifyContent="flex-start" alignItems="center" flexWrap="wrap">
                        <AttachmentCount count={squeegeeAssets?.length || 0} />
                        <AdvertTypeChip advertType={type} />

                        {tags.map((tag, index) => {
                            return <Chip key={index} label={tag} />;
                        })}
                    </Stack>
                    {!advert.isDirectTransfer && advert.price > 0 && (
                        <Stack gap={0.5} justifyContent="flex-end" mt={2}>
                            <Typography align="right">
                                <strong>Net Price:</strong>
                                <Price
                                    price={Number(updatedAdvert.price - priceBreakdown.serviceFee - priceBreakdown.transactionFee)}
                                    currency={advert.currency}
                                />
                            </Typography>
                            <Typography align="right">
                                <strong>Service Fee:</strong> <Price price={priceBreakdown.serviceFee} currency={advert.currency} />
                            </Typography>
                            <Typography align="right">
                                <strong>Transaction Fee:</strong> <Price price={priceBreakdown.transactionFee} currency={advert.currency} />
                            </Typography>
                            <Typography align="right">
                                <strong>Listing Price:</strong> <Price price={Number(updatedAdvert.price)} currency={advert.currency} />
                            </Typography>
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    <Button onClick={handleAdvertPublishToggle}>{advert.state === 'draft' ? 'Publish' : 'Unpublish'}</Button>
                    {advert.state === 'checkout' && (
                        <Button onClick={() => refreshCheckout()} variant="contained">
                            Refresh Checkout Status
                        </Button>
                    )}
                    {advert.state === 'paid' && advert.squeegeeAssets?.length && (
                        <Button onClick={() => setTransferDialogOpen(true)} variant="contained">
                            Transfer Assets
                        </Button>
                    )}
                    <Button onClick={handleAdvertSave} variant="contained" disabled={editsDisabled}>
                        {state === 'draft' ? 'Save' : state === 'published' ? 'Republish' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {publishDialogOpen && (
                <PublishAdvertAlertDialog
                    id={id}
                    onCancel={() => setPublishDialogOpen(false)}
                    onSuccess={handlePublish}
                ></PublishAdvertAlertDialog>
            )}
            {transferDialogOpen && (
                <TransferAssetsAlert id={id} onCancel={() => setPublishDialogOpen(false)} onSuccess={handlePublish}></TransferAssetsAlert>
            )}
        </>
    );
};

export default MyAdvertDetails2;
