import CreateBackupIcon from '@mui/icons-material/Backup';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UserIcon from '@mui/icons-material/Person';
import { default as AutomaticIcon, default as RestoreBackupIcon } from '@mui/icons-material/Restore';
import { Button, CircularProgress, Collapse, Divider, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import type { TreeItemProps } from '@mui/x-tree-view/TreeItem';
import { TreeItem as MuiTreeItem } from '@mui/x-tree-view/TreeItem';
import { DialogPaper, DialogRoot } from '@nexdynamic/nex-ui-react';
import { animated, useSpring } from '@react-spring/web';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { forwardRef, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { backupServer } from '../../../Data/backupServer';
import { createBackup, restoreBackup } from '../../../Data/createBackup';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';
import { Utilities } from '../../../Utilities';
import useStateFlags from '../../hooks/useStateFlags';
import useTranslation from '../../hooks/useTranslation';
import { useBackups } from './hooks/useBackups';
import { loadBackupIntoMemory } from './loadBackupIntoMemory';

dayjs.extend(advancedFormat);

const TransitionComponent = (props: TransitionProps) => {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
};

const TreeItem = forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <MuiTreeItem {...props} slots={{ groupTransition: TransitionComponent, ...props.slots }} ref={ref} />
));

const ManageBackupsDialogComponent = () => {
    const { mostRecentBackup, backupTree, refresh } = useBackups();
    const [backedUpThisSession, setBackedUpThisSession] = useState(false);
    const { t } = useTranslation();

    const restoreBackupUi = async (backup: { id: string; date: string }) => {
        restoreBackup(backup);
    };

    const downloadBackup = async (backup: { id: string }) => {
        if (!RethinkDbAuthClient.session) return;

        const { key, value } = RethinkDbAuthClient.session;
        const oneTimeLink = await Utilities.getShortUrl(
            `${backupServer()}/api/backups/${backup.id}?session-key=${key}&session-value=${value}&data-email=${encodeURIComponent(
                ApplicationState.dataEmail
            )}`
        );
        window.open(oneTimeLink, '_blank');
    };

    const createBackupUi = async () => {
        const createBackupResult = await createBackup();
        if (createBackupResult) refresh();
        setBackedUpThisSession(true);
        return createBackupResult;
    };

    const { devMode: isDev } = useStateFlags();
    if (!backupTree)
        return (
            <DialogRoot sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </DialogRoot>
        );

    return (
        <DialogRoot>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6">Most recent backup</Typography>
                <Divider sx={{ mb: 2 }} />
                {mostRecentBackup ? (
                    <ListItem disablePadding sx={{ px: 1 }}>
                        <ListItemText
                            primary={dayjs(mostRecentBackup.date).format('ddd Do MMM, h:mma')}
                            secondary={mostRecentBackup.size ? `${Utilities.formatBytes(mostRecentBackup.size)}` : 'Unknown size'}
                        />
                        {isDev && (
                            <Button
                                size="small"
                                variant="outlined"
                                color="info"
                                endIcon={<BrowserUpdatedIcon />}
                                onClick={() => loadBackupIntoMemory(mostRecentBackup)}
                                sx={{ mr: 1 }}
                            >
                                Load
                            </Button>
                        )}
                        <Button
                            size="small"
                            variant="contained"
                            color="error"
                            endIcon={<RestoreBackupIcon />}
                            onClick={() => restoreBackupUi(mostRecentBackup)}
                            sx={{ mr: 1 }}
                        >
                            Restore
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            endIcon={<CloudDownloadIcon />}
                            onClick={() => downloadBackup(mostRecentBackup)}
                        >
                            Download
                        </Button>
                    </ListItem>
                ) : (
                    <Typography variant="body1">There are no backups</Typography>
                )}
            </DialogPaper>
            {Object.keys(backupTree).length > 0 && (
                <DialogPaper sx={{ p: 2 }}>
                    <Typography variant="h6">Account Backup History</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <SimpleTreeView>
                        {Object.keys(backupTree).map(year => (
                            <TreeItem itemId={year} label={year} key={year}>
                                {Object.keys(backupTree[year]).map(month => (
                                    <TreeItem itemId={`${year}-${month}`} label={dayjs(`${year}-${month}-01`).format('MMMM')} key={month}>
                                        {Object.keys(backupTree[year][month]).map(week => (
                                            <TreeItem
                                                itemId={`${year}-${month}-${week}`}
                                                label={t('general.week-beginning', {
                                                    date: dayjs(backupTree[year][month][week].startDate).format('MMMM Do'),
                                                })}
                                                key={week}
                                            >
                                                {backupTree[year][month][week].backups.map((backup, index) => {
                                                    const manual = backup.id.includes('backup-manual-');
                                                    return (
                                                        <ListItem
                                                            key={backup.id}
                                                            divider={index < backupTree[year][month][week].backups.length - 1}
                                                        >
                                                            <ListItemText
                                                                primary={dayjs(backup.date).format('ddd Do h:mma')}
                                                                secondary={
                                                                    <Stack
                                                                        direction="row"
                                                                        spacing={1}
                                                                        alignItems={'center'}
                                                                        fontSize={'16px'}
                                                                    >
                                                                        <Typography>
                                                                            {backup.size
                                                                                ? `${Utilities.formatBytes(backup.size)}`
                                                                                : 'Unknown size'}
                                                                        </Typography>
                                                                        {manual ? (
                                                                            <UserIcon fontSize="inherit" />
                                                                        ) : (
                                                                            <AutomaticIcon fontSize="inherit" />
                                                                        )}
                                                                    </Stack>
                                                                }
                                                            />
                                                            {isDev && (
                                                                <Button
                                                                    size="small"
                                                                    variant="outlined"
                                                                    color="info"
                                                                    endIcon={<BrowserUpdatedIcon />}
                                                                    onClick={() => loadBackupIntoMemory(backup)}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Load
                                                                </Button>
                                                            )}
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="error"
                                                                endIcon={<RestoreBackupIcon />}
                                                                onClick={() => restoreBackupUi(backup)}
                                                                sx={{ mr: 1 }}
                                                            >
                                                                Restore
                                                            </Button>

                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="primary"
                                                                endIcon={<CloudDownloadIcon />}
                                                                onClick={() => downloadBackup(backup)}
                                                            >
                                                                Download
                                                            </Button>
                                                        </ListItem>
                                                    );
                                                })}
                                            </TreeItem>
                                        ))}
                                    </TreeItem>
                                ))}
                            </TreeItem>
                        ))}
                    </SimpleTreeView>
                </DialogPaper>
            )}
            <DialogPaper sx={{ p: 2 }}>
                <Button
                    disabled={backedUpThisSession}
                    variant="contained"
                    color="success"
                    endIcon={<CreateBackupIcon />}
                    onClick={createBackupUi}
                >
                    Run a Manual Backup Now
                </Button>
            </DialogPaper>
        </DialogRoot>
    );
};

export default ManageBackupsDialogComponent;
