import { CssBaseline, ThemeProvider } from '@mui/material';
import type { Themes } from '@nexdynamic/nex-ui-react';
import { getTheme } from '@nexdynamic/nex-ui-react';
import type React from 'react';
import { createRoot } from 'react-dom/client';
import { useSetting } from './hooks/useSetting';

const initThemeProvider = () => {
    const container = document.getElementById('squeegee-theme-provider');
    if (!container) {
        console.error('squeegee-theme-provider not found');
        return;
    }

    const root = createRoot(container);
    root.render(<SqueegeeThemeProvider />);
};

export const SqueegeeThemeProvider = ({ children }: { children?: React.ReactNode }) => {
    const [themeSetting] = useSetting('experiments.dark-mode', false);

    let theme: Themes;
    if (themeSetting === undefined) {
        theme = 'base';
    } else {
        theme = themeSetting ? 'dark' : 'light';
    }

    return (
        <ThemeProvider theme={getTheme(theme)}>
            {themeSetting && <CssBaseline />}
            {children}
        </ThemeProvider>
    );
};

export default initThemeProvider;
