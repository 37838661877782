import type { NotificationType } from '@nexdynamic/squeegee-common';
import { sortByCreatedDateDesc, type Notification } from '@nexdynamic/squeegee-common';
import { useCallback, useEffect, useState } from 'react';
import { Data } from '../../../Data/Data';
import { fetchNotifications } from '../../../Notifications/functions/fetchNotifications';
import { Api } from '../../../Server/Api';

const notificationTypes = (options: Options) => {
    const types: Array<NotificationType> = [];
    if (options.filters.inbound !== false) types.push('SMSReply', 'Feedback');
    if (options.filters.outbound !== false || options.filters.scheduled !== false) types.push('Email', 'SMS');

    return types;
};

const getFilteredNotifications = (options: Options) => {
    // For every customer get the latest notification
    const filteredNotifications: Array<Notification> = [];

    const types: Array<NotificationType> = notificationTypes(options);

    const nowInSeconds = new Date().valueOf() / 1000;
    const scheduledFilter =
        options.filters.scheduled === true
            ? (notification: Notification) =>
                  notification.sendAtSecondsTimestamp !== undefined ? notification.sendAtSecondsTimestamp >= nowInSeconds : false
            : (notification: Notification) =>
                  notification.sendAtSecondsTimestamp === undefined || notification.sendAtSecondsTimestamp <= nowInSeconds;

    for (const type of types) {
        const where: Partial<Notification> = {
            type,
        };
        if (options.filters.unreadOnly === true) where.unread = true;
        const results = Data.all<Notification>('notifications', scheduledFilter, where).slice();
        filteredNotifications.push(...results);
    }

    return filteredNotifications.sort(sortByCreatedDateDesc);
};
type Options = {
    filters: {
        inbound?: boolean;
        outbound?: boolean;
        unreadOnly?: boolean;
        scheduled?: boolean;
    };
    sortBy?: (a: Notification, b: Notification) => number;
};

type UseInboxMessages = (options?: Options) => {
    loading: boolean;
    notifications: Array<Notification>;
    error: boolean;
    hasMore: boolean;
    loadMore: () => Promise<void>;
};

export const useInboxMessages: UseInboxMessages = (
    options: Options = {
        filters: {
            inbound: true,
            outbound: true,
            unreadOnly: true,
            scheduled: true,
        },
    }
) => {
    const [notifications, setNotifications] = useState<Array<Notification>>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const loadMore = useCallback(
        async (reset?: boolean) => {
            try {
                const oldNotifications = reset ? [] : notifications;
                setLoading(true);

                let newNotifications: Array<Notification> = [];

                // If this is the first load check locally first
                if (oldNotifications.length === 0) {
                    newNotifications = getFilteredNotifications(options);
                }
                const take = 30;
                if (newNotifications.length < take && Api.isConnected) {
                    const remoteNotifications: Array<Notification> = await fetchNotifications({
                        take,
                        // Only skip the notifications that have been created on the server
                        skip:
                            oldNotifications.filter(n => Boolean(n.status !== 'pending')).length +
                            newNotifications.filter(n => Boolean(n.status !== 'pending')).length,
                        ...options.filters,
                    }).catch(error => {
                        console.error('Error fetching remote notifications', error);
                        return [];
                    });

                    setHasMore(remoteNotifications.length >= take);

                    newNotifications.push(...remoteNotifications);
                }

                setNotifications([...oldNotifications, ...newNotifications].sort(options.sortBy || sortByCreatedDateDesc));
            } catch (error) {
                console.error('Error fetching notifications', error);
                setError(true);
            } finally {
                setLoading(false);
            }
        },
        [notifications, options.filters]
    );

    useEffect(() => {
        setHasMore(true);
        loadMore(true);
    }, [options.filters.inbound, options.filters.outbound, options.filters.unreadOnly]);

    return {
        loadMore,
        hasMore,
        loading,
        notifications,
        error,
    };
};
