import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { Theme, ThemeOptions } from '@mui/material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Collapse,
    Divider,
    IconButton,
    Link,
    ListItemButton,
    ListItemText,
    Switch as MuiSwitch,
    Paper,
    Stack,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { Tag, TranslationKey } from '@nexdynamic/squeegee-common';
import { TagType, copyObject } from '@nexdynamic/squeegee-common';
import type { IDirectoryEntryDetailsService, StandardServiceId } from '@nexdynamic/squeegee-portal-common';
import { TextKeys, questionLibrary, type Service } from '@nexdynamic/squeegee-portal-common';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../../ApplicationState';
import { AttachmentService } from '../../../../Attachments/AttachmentService';
import { SelectAttachmentsDialog } from '../../../../Attachments/Dialogs/SelectAttachmentsDialog';
import { AureliaReactComponentDialog } from '../../../../Dialogs/AureliaReactComponentDialog';
import { DialogAnimation } from '../../../../Dialogs/DialogAnimation';
import { PortalTextDialog } from '../../../../DirectoryAndPortal/Dialogs/PortalTextDialog';
import type { PortalTheme } from '../../../../DirectoryAndPortal/types/PortalTheme';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import { SelectTagsDialog } from '../../../../Tags/Components/SelectTagsDialog';
import { Utilities } from '../../../../Utilities';
import { isDevMode } from '../../../../isDevMode';
import useTranslation from '../../../hooks/useTranslation';
import { ComponentSettingsDialog } from '../../ComponentSettings/ComponentSettingsDialog';
import type { ThemeEditorDialogProps } from '../../ThemeEditor/ThemeEditorDialog';
import ThemeEditorDialogComponent from '../../ThemeEditor/ThemeEditorDialog';
import { themePresets } from '../../ThemeEditor/themePresets';
import { SettingCard } from '../components/SettingCard';
import type { DirectoryViewStandardProps } from '../types/DirectoryViewStandardProps';
import type { Question } from '../types/Questions';
import { stripePrepayOptionsDialog } from './stripePrepayOptionsDialog';

export const Switch = styled(MuiSwitch)(({ theme }) => ({
    '& .MuiSwitch-thumb': {
        boxShadow: theme.shadows[2],
    },
}));

export const DropDownCategory = ({
    categoryKey,
    title,
    expanded,
    onToggle,
}: {
    categoryKey: string;
    title: string;
    expanded: boolean;
    onToggle: (key: string) => void;
}) => {
    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            px={1}
            mb={1}
            sx={{
                cursor: 'pointer',
            }}
            onClick={() => onToggle(categoryKey)}
        >
            <Typography variant="h6">{title}</Typography>
            <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s ease-in-out' }} />
        </Stack>
    );
};

type ExpandedCategories = {
    general: boolean;
    listingInformation: boolean;
    advertisedServices: boolean;
    other: boolean;
};

const SettingsView = ({ directory, onDirectoryChange }: DirectoryViewStandardProps) => {
    const isUltimateOrAbove = ApplicationState.hasUltimateOrAbove;
    const [expanded, setExpanded] = useState<ExpandedCategories>({
        general: true,
        listingInformation: true,
        advertisedServices: false,
        other: false,
    });

    const onExpand = (key: keyof ExpandedCategories) => {
        setExpanded(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleTheme = async () => {
        if (directory.config?.themeOptions) {
            if (!directory.config.themeOptions.palette) {
                directory.config.themeOptions.palette = createPalette({});
            }
            if (!directory.config?.themeOptions.palette.text) {
                directory.config.themeOptions.palette.text = {};
            }
        }
        const themeOptions: PortalTheme = {
            id: directory.config?.themeOptionsId || 'custom',
            name: directory.config?.themeName || 'Custom Theme',
            theme: (directory.config?.themeOptions as Theme) || themePresets[1].theme,
        };

        const dialog = new AureliaReactComponentDialog<PortalTheme, ThemeEditorDialogProps>({
            dialogTitle: 'directory.theme',
            isSecondaryView: true,
            component: ThemeEditorDialogComponent,
            componentProps: { currentThemeOptions: themeOptions },
        });

        const theme = await dialog.show();

        if (dialog.cancelled) return;

        onDirectoryChange('config', {
            ...directory.config,
            themeName: theme.name,
            themeOptionsId: theme.id,
            themeOptions: theme.theme as ThemeOptions,
        });
    };

    const handleWidgetSettings = async () => {
        const dialog = new ComponentSettingsDialog();

        await dialog.show();

        if (dialog.cancelled) return;
    };

    const handleTextKeys = async () => {
        const text = directory.config?.text || copyObject(TextKeys);
        const keys = Object.keys(TextKeys) as Array<keyof typeof TextKeys>;
        for (const key of keys) if (text[key] === undefined) text[key] = TextKeys[key];

        const dialog = new PortalTextDialog({ textSettings: { text, textDebug: directory.config?.textDebug || false } });
        const update = await dialog.show();
        if (dialog.cancelled) return;

        onDirectoryChange('config', {
            ...directory.config,
            text: update.text,
            textDebug: update.textDebug,
        });
    };

    const uploadLogo = async () => {
        const url = await selectUploadAttachment();
        if (url) onDirectoryChange('logo', url);
    };

    const uploadBanner = async () => {
        const url = await selectUploadAttachment();
        if (url) onDirectoryChange('banner', url);
    };

    const selectServices = async () => {
        const dialog = new SelectTagsDialog(
            (directory.services?.slice().map(dirService => dirService.service) as Tag[]) || [],
            TagType.SERVICE,
            0,
            undefined,
            undefined,
            undefined,
            isUltimateOrAbove
        );
        const services = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            let newServices = directory.services;
            if (!newServices) newServices = [];
            for (const service of services) {
                if (directory.services?.some(s => s.id === service._id)) continue;
                newServices.push({
                    service: service as Service,
                    id: service._id,
                    name: service.description,
                    description: '',
                    quoteQuestionConfig: {},
                    children: [],
                });
            }

            newServices = newServices.filter(s => services.some(ss => ss._id === s.service._id));
            onDirectoryChange('services', newServices);
        }
    };

    const selectUploadAttachment = async (): Promise<string | undefined> => {
        const attachmentDialog = new SelectAttachmentsDialog(undefined, { selectOne: true, isPublic: true });
        const attachment = (await attachmentDialog.show(DialogAnimation.SLIDE))?.[0];
        if (attachmentDialog.cancelled) return;

        if (!attachment.mimeType?.startsWith('image')) {
            new NotifyUserMessage('attachment.must-be-selected');
            return undefined;
        }
        if (!attachment.isPublic) {
            new NotifyUserMessage('attachment.must-be-public');
            return undefined;
        }

        return AttachmentService.getPublicUrl(attachment);
    };

    const allQuestions = useRef<Array<Question>>([]);
    useEffect(() => {
        const questionAnswers = [];
        for (const questionkey in questionLibrary) {
            const question = questionLibrary[questionkey];
            questionAnswers.push({
                ...question,
                id: questionkey,
                show: true,
            });
        }
        allQuestions.current = questionAnswers;
    }, []);

    const handleToggleQuestion = (service: IDirectoryEntryDetailsService, question: Question, checked: boolean) => {
        console.log('toggling', checked);
        const newService = { ...service };
        const newQuestionConfig = newService.quoteQuestionConfig || {};
        const newQuestion = { ...newQuestionConfig[question.id] };
        newQuestion.hide = !checked;
        newQuestionConfig[question.id] = newQuestion;
        newService.quoteQuestionConfig = newQuestionConfig;
        onDirectoryChange(
            'services',
            directory.services?.map(s => (s.id === service.id ? newService : s))
        );
    };

    const advertisedServicesEnabled = directory.services?.some(service => service.quoteQuestionConfig) || isDevMode();

    const stripeConnected = !directory.stripePublishableKey;
    const handleStripePrepayOptions = async () => {
        if (!directory) return;
        if (!stripeConnected) {
            new NotifyUserMessage('directory.stripe-not-connected');
            return;
        }

        const config = directory.config;
        if (!config) return;
        const currentPrepayOptions = config.prepayOptions || [];

        const dialog = await stripePrepayOptionsDialog(currentPrepayOptions);
        if (!dialog) return;
        const prepayOptions = await dialog.show();

        if (dialog.cancelled) return;

        onDirectoryChange('config', { ...config, prepayOptions });
    };

    const nav = useNavigate();
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();

    return (
        <Box sx={{ my: 2 }}>
            <DropDownCategory categoryKey="general" title="General" expanded={expanded.general} onToggle={onExpand} />
            <Collapse in={expanded.general}>
                <Stack gap={1} mb={2}>
                    <SettingCard
                        primary="Enable Hosted Portal"
                        secondary="Allow customers to view your portal online"
                        toggleComponent={
                            <Switch
                                checked={directory.portalEnabled === 'y'}
                                onChange={e => {
                                    onDirectoryChange('portalEnabled', e.target.checked ? 'y' : 'n');
                                    onDirectoryChange('isPublic', e.target.checked ? 'y' : 'n');
                                }}
                            />
                        }
                    />
                    <SettingCard
                        primary="Dense Layout"
                        secondary="Condense the layout to display more information on screen"
                        toggleComponent={
                            <Switch
                                checked={directory.config?.dense}
                                onChange={e => onDirectoryChange('config', { ...directory.config, dense: e.target.checked })}
                            />
                        }
                    />
                    {/* murdered
                    <SettingCard
                        primary="Service Quote Requests"
                        secondary="Allow customers to request quotes for specific services"
                        toggleComponent={
                            <Switch
                                checked={directory.quoteRequestEnabled}
                                onChange={e => onDirectoryChange('quoteRequestEnabled', e.target.checked)}
                            />
                        }
                    /> */}
                    <SettingCard
                        primary="URL Handle"
                        secondary={
                            <>
                                https://squeeg.ee/portal/<b>{directory.uniqueUrl}</b>
                            </>
                        }
                        inputComponent={
                            <TextField
                                size="small"
                                value={directory.uniqueUrl}
                                onChange={e => onDirectoryChange('uniqueUrl', e.target.value)}
                                fullWidth
                            />
                        }
                    />
                    <Divider sx={{ m: 0.5 }} />
                    <Paper sx={{ overflow: 'hidden' }}>
                        <ListItemButton onClick={handleTheme}>
                            <ListItemText primary="Edit Theme" secondary="Customize the look and feel of your portal" />
                            <Typography variant="caption">{directory.config?.themeName || ''}</Typography>
                            <ChevronRightIcon />
                        </ListItemButton>
                    </Paper>
                    <Paper sx={{ overflow: 'hidden' }}>
                        <ListItemButton onClick={handleTextKeys}>
                            <ListItemText primary="Edit Text" secondary="Customize the text content of your portal" />
                            <ChevronRightIcon />
                        </ListItemButton>
                    </Paper>
                    <Paper sx={{ overflow: 'hidden' }}>
                        <ListItemButton onClick={handleStripePrepayOptions}>
                            <ListItemText
                                primary={t('directory.config-prepayment-options')}
                                secondary={t('directory.config-prepayment-options-subtitle')}
                            />
                            <ChevronRightIcon />
                        </ListItemButton>
                    </Paper>
                    <Paper sx={{ overflow: 'hidden' }}>
                        <ListItemButton onClick={handleWidgetSettings}>
                            <ListItemText primary="Widget Settings" secondary="Configure the widgets on your portal" />
                            <ChevronRightIcon />
                        </ListItemButton>
                    </Paper>
                </Stack>
            </Collapse>
            <DropDownCategory
                categoryKey="listingInformation"
                title="Listing Information"
                expanded={expanded.listingInformation}
                onToggle={onExpand}
            />
            <Collapse in={expanded.listingInformation}>
                <Stack spacing={1} mb={2}>
                    <Stack direction="row" spacing={1} width="100%">
                        <SettingCard
                            primary="Logo"
                            secondary="Your business logo"
                            toggleComponent={
                                isSmallScreen ? (
                                    <Stack>
                                        <IconButton color="primary" onClick={uploadLogo} size="large">
                                            <CloudUploadIcon />
                                        </IconButton>
                                    </Stack>
                                ) : (
                                    <Button endIcon={<CloudUploadIcon />} onClick={uploadLogo}>
                                        Upload
                                    </Button>
                                )
                            }
                            sx={{ width: '50%' }}
                        >
                            {directory.logo && (
                                <img src={directory.logo} alt="Logo" style={{ maxHeight: 50, borderRadius: 4, marginTop: 4 }} />
                            )}
                        </SettingCard>
                        <SettingCard
                            primary="Cover Image"
                            secondary="A banner image for your portal"
                            toggleComponent={
                                isSmallScreen ? (
                                    <Stack>
                                        <IconButton color="primary" onClick={uploadBanner} size="large">
                                            <CloudUploadIcon />
                                        </IconButton>
                                    </Stack>
                                ) : (
                                    <Button endIcon={<CloudUploadIcon />} onClick={uploadBanner}>
                                        Upload
                                    </Button>
                                )
                            }
                            sx={{ width: '50%' }}
                        >
                            {directory.banner && (
                                <img src={directory.banner} alt="Cover" style={{ maxHeight: 50, borderRadius: 4, marginTop: 4 }} />
                            )}
                        </SettingCard>
                    </Stack>
                    <SettingCard primary="Business Name" secondary="The display name of your business">
                        <TextField
                            size="small"
                            fullWidth
                            value={directory.businessName}
                            onChange={e => onDirectoryChange('businessName', e.target.value)}
                        />
                    </SettingCard>
                    <SettingCard primary="Business Description" secondary="A brief description of your business">
                        <TextField
                            size="small"
                            multiline
                            rows={3}
                            fullWidth
                            value={directory.summary}
                            onChange={e => onDirectoryChange('summary', e.target.value)}
                        />
                    </SettingCard>
                    <SettingCard
                        primary="Business Number"
                        secondary="The contact number for your business"
                        inputComponent={
                            <TextField
                                size="small"
                                fullWidth
                                value={directory.telephone}
                                onChange={e => onDirectoryChange('telephone', e.target.value)}
                            />
                        }
                        optional
                    />
                    <SettingCard
                        primary="Business Email"
                        secondary="The contact email for your business"
                        inputComponent={
                            <TextField
                                size="small"
                                fullWidth
                                value={directory.email}
                                onChange={e => onDirectoryChange('email', e.target.value)}
                            />
                        }
                        optional
                    />
                    <SettingCard
                        primary="Business Address"
                        secondary="The physical address of your business"
                        inputComponent={
                            <TextField
                                size="small"
                                multiline
                                maxRows={3}
                                fullWidth
                                spellCheck="false"
                                value={directory.addressDescription}
                                onChange={e => onDirectoryChange('addressDescription', e.target.value)}
                            />
                        }
                        optional
                    />
                </Stack>
            </Collapse>
            {advertisedServicesEnabled && (
                <>
                    <DropDownCategory
                        categoryKey="advertisedServices"
                        title="Advertised Services"
                        expanded={expanded.advertisedServices}
                        onToggle={onExpand}
                    />
                    <Collapse in={expanded.advertisedServices}>
                        <Stack spacing={1} mb={2}>
                            <Typography color="textSecondary" sx={{ mb: 1, px: 1 }}>
                                {t('questions.deprecated-message')}
                            </Typography>
                            <Paper sx={{ overflow: 'hidden' }}>
                                <ListItemButton sx={{ py: '10px' }} onClick={selectServices}>
                                    <ListItemText primary="Select Services" secondary="Choose the services you offer" />
                                    <ChevronRightIcon />
                                </ListItemButton>
                            </Paper>
                            <Box>
                                {directory.services?.map(service => (
                                    <Accordion key={service.id}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{service.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography color="textSecondary">{t('questions.description')}</Typography>
                                            <Stack spacing={2}>
                                                {allQuestions.current.map(question => {
                                                    if (
                                                        question.serviceMapping &&
                                                        question.serviceMapping.includes(service.id as StandardServiceId)
                                                    )
                                                        return (
                                                            <Stack direction="row" spacing={1} width="100%" key={question.id}>
                                                                <Box flex={1}>
                                                                    <Typography fontWeight={500} sx={{ mb: 1 }}>
                                                                        {t(question.id)}
                                                                    </Typography>
                                                                    <Stack direction="row" spacing={1}>
                                                                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                                                            {question.answerOptions?.map(answer => (
                                                                                <Chip key={answer} label={t(answer as TranslationKey)} />
                                                                            ))}
                                                                        </Box>
                                                                    </Stack>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Switch
                                                                        checked={!service.quoteQuestionConfig?.[question.id]?.hide}
                                                                        onChange={e =>
                                                                            handleToggleQuestion(service, question, e.target.checked)
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        );
                                                    else return null;
                                                })}
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Box>
                        </Stack>
                    </Collapse>
                </>
            )}
            <DropDownCategory categoryKey="other" title="Other" expanded={expanded.other} onToggle={onExpand} />
            <Collapse in={expanded.other}>
                <Stack spacing={1} mb={2}>
                    <SettingCard
                        primary="Your Portal ID"
                        secondary={ApplicationState.account.uuid}
                        toggleComponent={
                            <Stack justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => Utilities.copyToClipboard(ApplicationState.account.uuid)}
                                >
                                    Copy
                                </Button>
                            </Stack>
                        }
                        inputComponent={
                            <Stack direction="row" spacing={1}>
                                <Link href={`https://nexdynamic.gitbook.io/squeegee-portal`} target="_blank" typography="body2">
                                    Portal Documentation
                                </Link>
                            </Stack>
                        }
                    />
                    <Paper sx={{ overflow: 'hidden' }}>
                        <ListItemButton sx={{ py: '10px' }} onClick={() => nav('/portal-directory/setup')}>
                            <ListItemText primary="Setup Wizard" secondary="Quickly setup your portal with a guided wizard" />
                            <ChevronRightIcon />
                        </ListItemButton>
                    </Paper>

                    <SettingCard
                        primary="Unpublish Portal"
                        secondary="Temporarily unpublish your portal"
                        toggleComponent={
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={() => onDirectoryChange('portalEnabled', 'n')}
                                disabled={directory.portalEnabled === 'n'}
                            >
                                {t('directory.unpublish')}
                            </Button>
                        }
                    />
                    {/* <SettingCard
                        primary="Delete Portal"
                        secondary="Permanently delete your portal"
                        inputComponent={
                            <Button variant="contained" color="error">
                                Delete
                            </Button>
                        }
                    /> */}
                </Stack>
            </Collapse>
        </Box>
    );
};

export default SettingsView;
